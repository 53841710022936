import { API } from "@/shared/API"
import { FieldAPI } from "@/entities/field"

export class HeadHunterResumeAPI {
  static downloadResumes(resumeIds) {
    let request = new API({
      method: "hh_ru.download_resumes_from_hh",
      params: {
        resume_ids: resumeIds
      }
    })
    return request.sendRequest()
  }

  // получение комментариев резюме с ХХ
  static async fetchGetResumeComments(ownerId) {
    let request = new API({
      method: "hh_ru.get_hh_applicant_comments",
      params: { owner_id: ownerId }
    })
    return request.sendRequest()
  }

  // запрос на получение типов комментариев для резюме
  static async fetchGetCommentsAccessTypes() {
    let request = new API({
      method: "hh_ru.get_applicant_comment_access_type"
    })
    return await request.sendRequest().then((data) => {
      FieldAPI.setDataFields({
        data,
        hasMethod: true,
        field: "commentsAccessTypes",
        fieldObject: "HHFields"
      })
    })
  }

  /**
   * добавление нового комментария в резюме
   * @param {{ownerId: number | string, text: string, accessType: string}} param
   * @returns {Promise}
   */
  static async fetchAddCommentToResume({ ownerId, text, accessType }) {
    let request = new API({
      method: "hh_ru.add_hh_applicant_comment",
      params: {
        owner_id: ownerId,
        text,
        access_type: accessType
      }
    })
    return await request.sendRequest()
  }
  /**
   * запрос для удаления комментария из резюме
   * @param {{ownerId: number | string, commentId: number | string}} param
   * @returns {Promise}
   */
  static async fetchDeleteCommentFromResume({ ownerId, commentId }) {
    let request = new API({
      method: "hh_ru.delete_hh_applicant_comment",
      params: { owner_id: ownerId, comment_id: commentId }
    })
    return await request.sendRequest()
  }

  // запрос на получение информации о резюме из отклика
  static fetchGetResponseInformation(url) {
    let request = new API({
      method: "vacancy.view_negotiation",
      params: { url }
    })
    return request.sendRequest()
  }

  // запрос для поиска новых резюме
  static fetchSearchResumes(params, page = 0) {
    let request = new API({
      method: "hh_ru.search_resumes_on_hh",
      params: {
        ...params,
        page
      }
    })
    return request.sendRequest()
  }
}
