import { API } from "@/shared/API"

export * from "./head-hunter"
export class ResumeAPI {
  /**
   *  метод для получения всех резюмешек
   * @param {?number} page номер страницы
   * @param {?number} perPage сколько резюме показывать на одной странице
   * @returns {Promise}
   */
  static async fetchResumes(page, perPage) {
    let request = new API({
      method: "candidate.get_resumes",
      params: {
        page: page,
        per_page: perPage
      }
    })
    return await request.sendRequest()
  }

  // метод для поиска резюме
  static async fetchSearchResumes(searchPhrase) {
    let request = new API({
      method: "core.search_everything",
      params: {
        search_phrase: searchPhrase /* @type string */,
        search_area: "resumes" /* @values resumes/vacancies */
      }
    })
    return await request.sendRequest()
  }

  /**
   * метод для создания резюме
   * @param {*} resumeDetails
   * @returns {Promise} возвращает объект с созданным резюме
   */
  static async fetchCreateResume(resumeDetails) {
    let request = new API({
      method: "candidate.resume_create",
      params: resumeDetails
    })
    return await request.sendRequest()
  }

  /**
   * метод удаления резюме
   * @param {number[] | string[]} ids
   * @returns {Promise} возвращает
   */
  static async fetchDeleteResumes(ids) {
    let request = new API({
      method: "candidate.resume_delete",
      params: {
        resume_ids: ids
      }
    })
    return await request.sendRequest()
  }

  /**  метод получения  детальной информации по резюме
   * @param {string | number} id
   * @return {Promise}
   */
  static async fetchResumeDetails(id) {
    let request = new API({
      method: "candidate.resume_details",
      params: {
        resume_id: id
      }
    })
    return await request.sendRequest()
  }

  /**
   * метод для изменения данных резюме
   * @param {*} resumeDetails
   * @returns {Promise}
   */
  static async fetchUpdateResumeDetails(resumeDetails) {
    let request = new API({
      method: "candidate.resume_update",
      params: resumeDetails
    })
    return await request.sendRequest()
  }

  // метод для изменения ступени в вакансии у резюме и добавления резюме и в вакансию и в канбан
  static async fetchResumeUpdateStepForVacancy({ vacancyId, resumeId, step }) {
    let request = new API({
      method: "vacancy.move_resume_step_by_step_by_vacancy_stage",
      params: {
        vacancy_id: vacancyId /* @type number */,
        resume_id: resumeId /* @type number */,
        step: step /* @type string */
      }
    })
    return await request.sendRequest()
  }

  // метод для добавления резюме в вакансию (но не в канбан)
  static async fetchAddResumeToVacancy({ vacancyId, resumeIds }) {
    let request = new API({
      method: "vacancy.add_resume_to_vacancy",
      params: {
        vacancy_id: vacancyId /* @type number */,
        resume_ids: resumeIds /* @type array of numbers */
      }
    })
    return await request.sendRequest()
  }

  // метод для удаления резюме из вакансии и из канбана вакансии
  static async fetchDeleteResumeFromVacancy({ vacancyId, resumeIds }) {
    let request = new API({
      method: "vacancy.remove_resume_from_vacancy",
      params: {
        vacancy_id: vacancyId /* @type number */,
        resume_ids: resumeIds /* @type array of numbers */
      }
    })
    return await request.sendRequest()
  }
}
