import { instance } from "@/shared/API"
import { checkToken } from "@/entities/user"
import { abortController } from "@/shared/API"

// хук для отправки запросов на сервер с токеном пользователя
export default async function useFetch(requestBody) {
  try {
    const response = await instance.post("", { ...requestBody, signal: abortController.signal })

    return checkToken(
      response,
      /* колбек с запросом, если токен обновиться
        и нужно снова отправлять предшествующий запрос */
      () => instance.post("", { ...requestBody, signal: abortController.signal })
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}
