<script setup>
import { ref } from "vue"
import SpinerComponent from "./SpinerComponent.vue"

const props = defineProps({
  btnType: {
    type: String,
    default: "primary",
    validator: (val) => ["primary", "secondary", "link", "linear"].indexOf(val) != -1
  },
  hasLoading: {
    type: Boolean,
    default: false
  },
  btnSize: {
    type: String,
    default: "big",
    validator: (val) => ["big", "medium", "small"].includes(val)
  },
  onlyIcon: Boolean
})

let isLoading = ref(false)

let setBtnLoadingStatus = (val) => (isLoading.value = val)

defineExpose({ setBtnLoadingStatus })
</script>

<template>
  <button
    class="button"
    :class="{
      button_primary: btnType == 'primary',
      button_secondary: btnType == 'secondary',
      button_link: btnType == 'link',
      button_linear: btnType == 'linear',
      big: btnSize == 'big',
      medium: btnSize == 'medium',
      small: btnSize == 'small'
    }"
    :disabled="hasLoading == true || isLoading == true"
  >
    <span class="button-icon" :class="{ 'disable-slot': hasLoading == true || isLoading == true }">
      <slot name="icon"></slot>
    </span>
    <p
      v-if="onlyIcon == false"
      class="button-text"
      :class="{ 'disable-slot': hasLoading == true || isLoading == true }"
    >
      <slot></slot>
    </p>
    <spiner-component
      v-if="hasLoading == true || isLoading == true"
      class="button-spiner"
      :theme="['linear', 'link'].indexOf(btnType) != -1 ? 'dark' : null"
    />
  </button>
</template>

<style lang="scss" scoped>
@import "@/app/styles/UI-kits/buttons.scss";
</style>
