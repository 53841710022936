import { API } from "@/shared/API"

export class HeadHunterVacancyApi {
  // запрос на публикацию изменений по вакансии на HH
  static async fetchUploadVacancyOnHH({ vacancyId, vacancyDescription, ignoreDuplicates = false }) {
    let request = new API({
      method: "vacancy.post_vacancy_on_hh",
      params: {
        vacancy_id: vacancyId,
        hh_description: vacancyDescription,
        ignore_duplicates: ignoreDuplicates
      }
    })

    return await request.sendRequest()
  }

  // метод для загрузки вакансий с ХХ (id работодателя нужно брать из поля для вакансий employers)
  static async fetchDownloadVacancysFromHH({ employerId, archivedStatus }) {
    let request = new API({
      method: "vacancy.download_vacancies_from_hh",
      params: {
        employer_id: employerId,
        status: archivedStatus
      }
    })
    return request.sendRequest()
  }
  // метод для асинхронной загрузки вакансий с ХХ (id работодателя нужно брать из поля для вакансий employers)
  static async fetchAsyncDownloadVacancysFromHH({ employerId, archivedStatus }) {
    let request = new API({
      method: "vacancy.async_download_vacancies_from_hh",
      params: {
        hh_employer_id: employerId,
        status: archivedStatus
      }
    })
    return request.sendRequest()
  }
}
