<script>
export default {
  props: {
    resumeId: { type: [Number, String] },
  },
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="kanban-resume-card">
    <div class="kanban-resume-card__container">
      <div class="kanban-resume-card__top-block">
        <p class="kanban-resume-card__card-name">
          <slot name="card-name"></slot>
        </p>
        <div class="avatar-span">
          <slot name="avatar-image"></slot>
        </div>
      </div>
      <div class="kanban-resume-card__contact-item">
        <p class="kanban-resume-card__email">
          <slot name="card-email"></slot>
        </p>
        <p class="kanban-resume-card__contact-item">
          <slot name="card-phone"></slot>
        </p>
      </div>
      <div class="kanban-resume-card__bottom-block">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/app/styles/entites/resumes/resume-card.scss";
</style>
