import _ from "lodash";

export default {
  // добавляет резюме, если не находит его в стейте
  ADD_RESUME(store, resumeData) {
    let storeResumes = store.resumes.resumeArray;

    let resumeIndex = storeResumes.findIndex(
      (resume) => resume.id === resumeData.id
    );

    if (resumeIndex == -1) {
      storeResumes.push(resumeData);
    } else {
      this.commit("UPDATE_RESUME_DETAILS", { id: resumeData.id, resumeData });
    }
  },
  SORT_UNIQ_RESUMES(store) {
    _.uniqBy(store.resumes.resumeArray, (el) => el.id);
  },
  // добавление значений в стейт с резюмешками
  SET_RESUMES(store, { resumes }) {
    console.log(resumes);
    if (resumes != null) {
      resumes.forEach((resume) => {
        this.commit("ADD_RESUME", resume);
      });
    }
  },
  // установка текущей страницы для резюме
  SET_PAGE(store, page) {
    store.resumes.page = page;
  },
  SET_MAX_PAGE(store, maxPage) {
    store.resumes.maxPageCount = maxPage;
  },
  // обновление иформации по резюме
  UPDATE_RESUME_DETAILS(store, { id, resumeData }) {
    let resumeIndex = store.resumes.resumeArray.findIndex((el) => el.id == id);

    if (resumeIndex > -1) {
      let resumeObjectKeys = Object.getOwnPropertyNames(resumeData);
      resumeObjectKeys.forEach((key) => {
        if (key === "files") {
          console.log(resumeData[key]);
          resumeData[key] != null &&
            resumeData[key].forEach((el) => {
              if (el.file != null && el.file_path == null) {
                el.file_path = el.file;
                delete el.file;
              }
            });
          // ____________________________________________________________________________________
        }
        store.resumes.resumeArray[resumeIndex][key] = resumeData[key];
      });
    } else {
      store.resumes.resumeArray.push(resumeData);
    }
  },
  // убаление выбранных резюме
  DELETE_RESUMES(store, ids) {
    if (ids != null && ids != "") {
      store.resumes.resumeArray = store.resumes.resumeArray.filter(
        (el) => !ids.includes(el.id)
      );
    }
  },
  // добавление вакансий, в которых участвует резюме
  ADD_VACANCYS_TO_RESUME(store, { vacancys, resumeId }) {
    let resumesState = store.resumes.resumeArray;
    let mainResume = resumesState.find((el) => el.id == resumeId);

    console.log(mainResume);

    if (mainResume != null) {
      if (mainResume.vacancies == null) mainResume.vacancies = [];
      mainResume.vacancies = [...mainResume.vacancies, ...vacancys];
    }
  },
  // удаление вакансий, в которых участвует резюме
  REMOVE_VACANCY_FROM_RESUME(store, { vacancyId, resumeId }) {
    let resumesState = store.resumes.resumeArray;
    let resume = resumesState.find((el) => el.id === resumeId);

    let vacancyIndex =
      resume != null && resume.vacancies != null && resume.vacancies.length > 0
        ? resume.vacancies.findIndex((el) => el.id === vacancyId)
        : null;
    let stepsVacancyIndex =
      resume != null &&
      resume.steps_vacancy != null &&
      resume.steps_vacancy.length > 0
        ? resume.steps_vacancy.findIndex((el) => el.vacancy === vacancyId)
        : null;

    if (vacancyIndex !== null) {
      resume.vacancies.splice(vacancyIndex, 1);
      resume.steps_vacancy.splice(stepsVacancyIndex, 1);
    }
  },
  //
  ADD_STEPS_VACANCYS_TO_RESUME(store, { resumeId, steps }) {
    let resumesState = store.resumes.resumeArray;
    let mainResume = resumesState.find((el) => el.id == resumeId);

    if (mainResume != null) {
      mainResume.steps_vacancy = [...mainResume.steps_vacancy, ...steps];
    }
  },
};
