import { createRouter, createWebHistory } from "vue-router";
import authorization from "./router-paths/authorization-paths.js";
import registration from "./router-paths/registration-paths.js";
import userHrPaths from "./router-paths/users/hrs/hr-paths.js";
import checkingAuthorization from "./model/checking-auth";
import updateHhToken from "@/app/router/model/update-hh-token";
import activateEmail from "@/app/router/model/activate-email.js";

// redirect: "/vhod",
// component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')

const routes = [
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: true, title: "Главная" },
    redirect: "/hr-main-page",
  },
  ...authorization,
  ...registration,
  ...userHrPaths,
  {
    path: "/404",
    name: "not-found",
    alias: "/not-found",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/404PageView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;

let renameDocument = () =>
  router.beforeEach((to) => {
    console.log(to);
    if (to.query.title != null) {
      document.title = `HR ассистент - ${to.query.title}`;
    } else {
      document.title = `HR ассистент - ${to.meta.title}`;
    }
  });

renameDocument();
activateEmail();
checkingAuthorization();
updateHhToken();
