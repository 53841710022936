import { refreshAccessToken } from "./index"
import { abortController } from "@/shared/API"

export async function checkToken(data, callBack) {
  try {
    if (data.error && data?.code == -32011) {
      // обновление токена, если пришла ошибка токена
      let { error } = await refreshAccessToken(localStorage.refreshTokenHR)
      if (error) throw error
      if (!error) return callBack() // отправка колбека с предыдущим запросом
    }
    return data
  } catch (error) {
    abortController.abort()
    throw error
  }
}
