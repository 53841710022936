import router from "../index.js"
import { UserAPI } from "@/entities/user"

export default function activateEmail() {
  router.beforeEach(async (to) => {
    if (
      to != null &&
      to.query != null &&
      to.query.uidb64 &&
      to.query.token &&
      to.query.action &&
      to.query.action === "email_confirmation"
    ) {
      let { uidb64, token, action } = to.query
      return await UserAPI.activateEmail({ uidb64, token, action }).then((data) => {
        console.log(data)
        // if (data.error === true) {
        //   return router.push({ name: "not-found" });
        // }
        if (data.error === false) {
          return router.push({ name: "home" })
        }
      })
    }
    return
  })
}
