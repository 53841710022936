import { UserAPI, setTokens } from "@/entities/user"

export async function logIn({ login, password }) {
  try {
    const { error, result, message } = await UserAPI.getTokens({ login, password })
    if (result && error) throw { message }

    setTokens({
      access: result.access,
      refresh: result.refresh
    })
    return {
      error: false,
      data: result
    }
  } catch (error) {
    console.log(error)
    return {
      error: true,
      message: error.message
    }
  }
}
