export default [
  {
    path: "/sign-in",
    name: "sign-in",
    alias: "/vhod",
    meta: { title: "Вход" },
    component: () =>
      import(
        /* webpackChunkName: "sign-in" */ "@/views/authorization/SignInView.vue"
      ),
  },
];
