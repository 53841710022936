export default {
  vacancys: {
    active: {
      page: 0,
      maxPageCount: 0,
      activeArr: []
    },
    archived: {
      page: 0,
      maxPageCount: 0,
      archivedArr: []
    }
  },
  vacancysSteps: [],
  vacancyFields: {
    billingTypes: null,
    statusStaff: null,
    cities: null,
    skills: null,
    departments: null,
    driverLicenseTypes: null,
    addressWorkDetailed: null,
    schedule: null,
    employment: null,
    employers: null,
    salaryCurrency: null,
    professionalRoles: null,
    workPositions: null,
    workers: null,
    statuses: null
  }
}
