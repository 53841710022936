export const FieldGetters = {
  // возвращает все поля
  getFields(store) {
    return store.fields
  },
  getHHFields(store) {
    return store.HHFields
  },
  getAvitoFields(store) {
    return store.AvitoFields
  },
  getFieldByType:
    (store) =>
    ({ type /* тип  */, field /* в */, fieldsObject = "fields" }) => {
      if (field == "genders") {
        return store.fields.genders.find((el) => el.value == type)
      }
      if (store[fieldsObject][field] != null) {
        return store[fieldsObject][field].find((el) => el.type == type)
      }
    },
  getFieldByName:
    (store) =>
    ({ name, field }) => {
      if (store.fields[field] != null) {
        return store.fields[field].find((el) => el.name == name)
      }
    }
}
