<template>
  <span class="spiner">
    <div
      v-bind="$attrs"
      class="spiner_circle"
      :class="{ spiner_circle_dark: theme == 'dark' }"
    ></div>
  </span>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: null,
      validator: (val) => [null, "dark"].indexOf(val) != -1,
    },
  },
};
</script>

<style lang="scss" scoped>
.spiner_circle {
  display: block;
  width: 20px;
  height: 20px;
  border-top: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
  border-left: 2px solid #fff !important;
  border: 2px solid transparent;
  border-radius: 100%;
  animation: spiner 1s linear infinite;

  &_dark {
    border-top: 2px solid #283149 !important;
    border-bottom: 2px solid #283149 !important;
    border-left: 2px solid #283149 !important;
  }

  @keyframes spiner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

#app.dark {
  .spiner_circle {
    border-top: 2px solid #fff !important;
    border-bottom: 2px solid #fff !important;
    border-left: 2px solid #fff !important;
  }
}
</style>
