/**
 * функция для форматирования цвета из HEX в RGB
 * @param {string} c цвет в формате -> #333333
 * @returns
 */
export function colorConverter(c) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}
