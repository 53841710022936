<script>
import { onMounted, ref, toRefs } from "vue";

export default {
  props: {
    modelValue: [String, Number],
    placeholder: String,
  },
  setup(props, { emit, expose }) {
    // инициализация реактивных переменных
    let { modelValue, placeholder } = toRefs(props);
    let searchInput = ref(null),
      hasLoading = ref(null),
      searchField = ref(null);

    //  отправка данных к родителю
    //    эмитим данные из поля для ввода
    let emitModelValue = (event) =>
      emit("update:modelValue", event.target.value);
    //    эмитим событие отправки значений из поля
    let onSend = () => {
      if (modelValue.value == null || modelValue.value == "") {
        searchInput.value.focus();
      } else {
        emit("send");
      }
    };

    // -------------------------------------------------------

    //  функции
    //    изменение статуса загрузки для вывода спинера
    let setLoadingStatus = (val) => (hasLoading.value = val);
    //    функция отлавливания события нажатия на кнопку Enter при фокусированном поле для ввода
    let eventHandlerOnFocusEnter = (event) => {
      if (
        searchInput.value != null &&
        event.key == "Enter" &&
        document.activeElement == searchInput.value
      ) {
        onSend(); /* отправка эмита для сообщения родителю о событии отправки */
      }
    };
    //    добавление обработчика для события нажатия кнопки
    let addListenerOnFocusEnter = () => {
      document.addEventListener("keyup", eventHandlerOnFocusEnter);
    };
    //    удаление обработчика для события нажатия кнопки
    let removeListenerOnFocusEnter = () => {
      document.removeEventListener("keyup", eventHandlerOnFocusEnter);
    };

    // -------------------------------------------------------

    // хуки жизненного цикла
    onMounted(() => {
      addListenerOnFocusEnter();
    });

    // -------------------------------------------------------

    // разрешенные данные для внешнего использования компонента
    expose({
      setLoadingStatus,
      searchField,
      removeListenerOnFocusEnter,
      addListenerOnFocusEnter,
    });
    return {
      modelValue,
      emitModelValue,
      placeholder,
      searchInput,
      onSend,
      setLoadingStatus,
      hasLoading,
      searchField,
    };
  },
};
</script>

<template>
  <span ref="searchField" class="search-field">
    <!-- инпут для ввода текста -->
    <input
      ref="searchInput"
      class="search-field__input"
      :value="modelValue"
      @input="emitModelValue"
      :placeholder="placeholder"
    />
    <!-- кнопка для очищения поля -->
    <button
      :class="{ 'opacity-0': modelValue == null || modelValue == '' }"
      @click="() => $emit('update:modelValue', null)"
      class="search-field__clear-button"
    >
      <i
        class="animate__animated animate__bounceIn pi pi-times search-field__icon"
      />
    </button>
    <!-- кнопка отправки -->
    <span @click="onSend" class="search-field__icon-span">
      <i
        v-if="modelValue == null || modelValue == ''"
        class="animate__animated animate__bounceIn pi pi-search search-field__icon"
      ></i>
      <spiner-component
        class="animate__animated animate__bounceIn"
        v-else-if="hasLoading == true"
        theme="dark"
      >
      </spiner-component>
      <i
        v-else
        class="animate__animated animate__bounceIn pi pi-arrow-right search-field__icon"
      ></i>
    </span>
  </span>
</template>

<style lang="scss" scoped>
.search-field {
  @apply mr-3 flex border border-solid border-pink rounded items-stretch relative;

  &:has(input:focus) {
    @apply default-box-shadow;
  }

  &__clear-button {
    height: 100%;
    @apply block px-3 rounded-l h-auto;
  }

  &__input {
    @apply pl-4 py-3  min-w-[250px] bg-transparent;
  }

  &__icon-span {
    @apply flex px-4 cursor-pointer items-center border-l border-solid border-l-pink rounded-r;

    &:hover {
      @apply primary-gradient h-auto;
      i {
        color: $white;
      }
    }
  }

  &__icon {
    @apply text-lg text-pink;
  }
}
</style>
