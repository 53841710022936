<script>
import { ref } from "vue";
export default {
  props: {},
  components: {},
  setup(props, { expose }) {
    let popup = ref(null);
    let notification = ref({});

    // функция активации окошка
    let activate = () => popup.value.activate();
    // функция активации всплывающего окошка сразу с сообщениием (без пропса)
    let activateWithMessage = (error, message) => {
      notification.value.error = error;
      notification.value.message = message;
      activate();
    };

    expose({ activateWithMessage });
    return { popup, activate, activateWithMessage, notification };
  },
};
</script>
<template>
  <default-pop-up ref="popup">
    <div
      class="result-popup__container flex flex-col justify-center items-center"
    >
      <span class="check-span">
        <i
          v-if="
            notification != null && notification.error == false
              ? true
              : notification == null && result != null && result.error == false
              ? true
              : false
          "
          class="pi pi-check"
        >
        </i>
        <i
          v-if="
            notification != null && notification.error == true
              ? true
              : notification == null && result != null && result.error == true
              ? true
              : false
          "
          class="pi pi-times"
        >
        </i>
      </span>
      <h1
        class="mb-3"
        v-if="
          notification != null && notification.error == false
            ? true
            : notification == null && result != null && result.error == false
            ? true
            : false
        "
      >
        Успешно!
      </h1>
      <h1
        class="mb-3"
        v-if="
          notification != null && notification.error == true
            ? true
            : notification == null && result != null && result.error == true
            ? true
            : false
        "
      >
        Ошибка!
      </h1>
      <span class="block w-[90%]">
        <p class="result-popup__text">
          {{
            notification != null
              ? notification.message
              : notification == null && result != null
              ? result.message
              : null
          }}
        </p>
      </span>
    </div>
  </default-pop-up>
</template>

<style lang="scss" scoped>
// result popup
.result-popup {
  &__text {
    text-align: center;
    line-height: 20px;
  }
}

.result-popup__container {
  width: 500px;
}
.check-span {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-bottom: 12px;
  @apply flex justify-center items-center bg-gradient-to-r from-pink to-purple px-6 py-3;

  i {
    font-size: 30px;
    color: $white;
  }
}

#app.dark {
  .result-popup__container {
    * {
      color: $white;
    }
  }
}
</style>
