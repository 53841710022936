import { API } from "@/shared/API"
import { FieldAPI } from "@/entities/field"
import store from "@/store"

class AvitoFieldAPI {
  /**
   * функция для публикации вакансии на Авито
   * @param {Number} id
   * @returns
   */
  async fetchPublishVacancy(id) {
    const request = new API({ method: "vacancy.post_vacancy_on_avito", params: { vacancy_id: id } })
    return await request.sendRequest()
  }

  async fetchFields() {
    const methodsList = ["avito.get_resume_gender"]

    const storeFields = ["gender"]

    for (let method of methodsList) {
      let request = new API({ method: method })

      // поиск индекса метода в списке
      let index = methodsList.findIndex((el) => el == method)

      // отправлять запрос только если пустой стейт
      if (store.getters.getAvitoFields[storeFields[index]] == null) {
        await request.sendRequest().then((data) => {
          FieldAPI.setDataFields({
            data,
            hasMethod: true,
            field: storeFields[index],
            fieldObject: "AvitoFields"
          })
          return data
        })
      }
    }
  }
}

export default new AvitoFieldAPI()
