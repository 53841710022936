<!-- этот компонент всплывающего окошка, которое появляется справа -->
<script>
import { ref } from "vue";

export default {
  setup(props, { expose, emit }) {
    let popup = ref(null),
      refBackground = ref();

    let activate = () => {
      popup.value != null && popup.value.classList.add("active");
      emit("activate");
    };
    let deActivate = () => {
      popup.value != null && popup.value.classList.remove("active");
      emit("deactivate");
    };

    expose({ activate, deActivate });
    return { popup, refBackground, activate, deActivate };
  },
};
</script>

<template>
  <div
    ref="popup"
    class="right-side-popup default-popup animate__animated animate__fadeIn"
  >
    <div class="right-side-popup__container">
      <slot name="left-block"></slot>
      <div
        class="right-side-popup__popup-card animate__animated animate__fadeInRight"
      >
        <div class="popup-card__container">
          <span
            class="popup-card__close-button cancel"
            @click="deActivate"
          ></span>
          <slot></slot>
        </div>
      </div>
    </div>
    <span
      class="block right-side-popup__background default-popup__background"
      ref="refBackground"
      @click="deActivate($event)"
    ></span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/app/styles/UI-kits/pop-up-styles.scss";

#app.dark {
  .popup-card__container {
    background: $dark !important;
  }
}
.right-side-popup {
  &__container {
    @apply flex justify-end p-3 w-fit h-full absolute right-0;
  }

  &__popup-card {
    @apply max-w-[100%] lg:max-w-[100%] md:max-w-[80%] min-w-[100%] lg:min-w-[300px] md:min-w-[300px] h-full ml-3 right-0;
    .popup-card__container {
      @apply p-3 h-full bg-white rounded overflow-y-auto overflow-x-hidden;
    }

    .popup-card__close-button {
      @apply m-2;
    }
  }
}
</style>
