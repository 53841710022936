<script>
import { ref } from "vue";

export default {
  props: {
    hasCancel: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit, expose }) {
    let activePopUp = ref(false);
    let activate = () => {
      activePopUp.value = true;
      emit("activate");
    };

    let deActivate = () => {
      activePopUp.value = false;
      emit("deactivate");
    };
    expose({ deActivate, activate });
    return { activePopUp, activate, deActivate };
  },
};
</script>
<template>
  <div
    class="default-popup animate__animated animate__fadeIn"
    :class="{ active: activePopUp == true }"
  >
    <div class="default-popup__card">
      <div class="card__container">
        <slot></slot>
      </div>
      <span
        class="cancel"
        @click="deActivate()"
        v-if="hasCancel == true"
      ></span>
    </div>
    <span
      class="default-popup__background"
      ref="closeElement"
      @click="deActivate()"
    >
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/app/styles/UI-kits/pop-up-styles.scss";
</style>
