import { ViewerAPI } from "@/entities/viewer"

export const ViewerActions = {
  async requestUserInfo({ commit }) {
    return await ViewerAPI.fetchUserInfo().then((data) => {
      if (data.error == false) {
        commit("SET_USER_INFO", data.result)
      }
    })
  }
}
