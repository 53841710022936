export default [
  {
    path: "/registration",
    name: "registration-page",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/RegistrationView.vue"
      ),
    meta: { title: "Регистрация" },
  },
  // TODO: страница с проверкой номера телефона
];
