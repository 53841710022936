export default {
    getResumes(store) {
      return store.resumes.resumeArray;
    },
    getResumeById: (store) => (id) => {
      let resume = store.resumes.resumeArray.find((el) => el.id == id);
      console.log(resume);
      return resume;
    },
    getMaxPageCountResumes(store) {
      return store.resumes.maxPageCount;
    },
    getMainPageResumes(store) {
      return store.resumes.page;
    },
    getSavedStatusForHHResume: (store) => (hhId) => {
      let resumeIndex = store.resumes.resumeArray.findIndex(
        (el) => el.hh_resume_id === hhId
      );
      if (resumeIndex > -1) return true;
      else return false;
    },
  };
  