export default {
  getCollectionById:
    () =>
    ({ vacancy, collectionId }) => {
      return vacancy != null
        ? vacancy.reponses_collections.find(
            (collection) => collection.id == collectionId
          )
        : null;
    },
};
