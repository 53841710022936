import router from "../index.js";
export default function checkingAuthorization() {
  router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requiresAuth === true)) {
      isAutorizate() ? next() : next({ name: "sign-in" });
    } else {
      next();
    }
    return to;
  });
}

function isAutorizate() {
  if (localStorage.AuthorizationTokenHr != null) {
    return true;
  }
  return false;
}
