import { ResumeAPI } from "@/entities/resume"

export default {
  // запрос на получение всех резюме у рекрутера
  async requestAllResumes({ commit, state }, { page = 0, perPage = 50, forced }) {
    if (state.resumes.resumeArray.length <= 1 || forced == true) {
      return await ResumeAPI.fetchResumes(page, perPage).then((data) => {
        if (data.error === false) {
          if (page != null && perPage != null) {
            commit("SET_RESUMES", {
              resumes: data.result.resumes
            })
            commit("SET_MAX_PAGE", data.result.pages)
          }
          // если принудительно подгрузились все резюме, которые есть
          if ((page == null && perPage == null) || forced == true) {
            commit("SET_RESUMES", {
              resumes: data.result.resumes
            })
            // устанавливаем минимальные значения для пагинации, чтоб лишний раз не отправлялся запрос
            commit("SET_MAX_PAGE", 0)
            commit("SET_PAGE", 1)
          }
        }
        return data
      })
    }
  },
  // запрос на получение детальной информации по резюме
  async requestGetResumeDetails({ commit }, { resumeId }) {
    return await ResumeAPI.fetchResumeDetails(resumeId).then((data) => {
      console.log(data)
      if (data && data.error === true) {
        return data
      }
      if (data && data.error === false) {
        commit("UPDATE_RESUME_DETAILS", {
          id: resumeId,
          resumeData: data.result
        })
      }
      return data
    })
  },
  // запрос на обновление информации по резюме
  async requestEditResume({ commit }, { resumeDetails, resumeId }) {
    return await ResumeAPI.fetchUpdateResumeDetails(resumeDetails).then((data) => {
      if (data && data.error === true) throw data.message
      if (data.error === false) {
        commit("UPDATE_RESUME_DETAILS", {
          id: resumeId,
          resumeData: data.result
        })
      }
      return data
    })
  }
}
