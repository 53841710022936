<script setup>
import {
  returnName,
  getAgeCaseWord,
  beautifyNumber,
  getCurrencyIconFromString as getCurrency,
  getYearsFromMonths,
  getMonthsCaseWord
} from "@/shared/lib/helpers"
import { AvatarComponent } from "@/shared/UI-kits/avatar"
import { toRefs, computed } from "vue"
import { ResumeCardExperienceComponent as ExperienceComponent } from "./index"

const props = defineProps({
    resume: {
      type: Object,
      required: true
    }
  }),
  { resume } = toRefs(props)

const totalExperience = computed(() => getYearsFromMonths(resume.value?.total_experience?.months))
</script>

<template>
  <div class="resume-card">
    <div class="resume-card__container">
      <div class="resume-card__header">
        <div class="header__avatar">
          <avatar-component :width="70" :height="70" :image="resume.photo?.medium" />
        </div>
        <div class="header__right-side">
          <div class="right-side__top">
            <p class="top__title" v-if="resume.title != null">
              <slot name="title" :value="resume.title">
                <a :href="resume.alternate_url">
                  {{ resume.title }}
                </a>
              </slot>
            </p>
            <slot name="download-button" :value="resume" />
          </div>
          <div class="right-side__bottom">
            <p class="bottom__name" v-if="returnName(resume) != null">
              {{ returnName(resume) }}
            </p>
            <p class="bottom__age" v-if="resume.age">
              {{ resume.age + " " + getAgeCaseWord("" + resume.age) }}
            </p>
            <span class="bottom__gender">
              <p v-if="resume.gender.id == 'female'">женщина</p>
              <p v-if="resume.gender.id == 'male'">мужчина</p>
            </span>
          </div>
        </div>
      </div>
      <p class="resume-card__salary" v-if="resume.salary?.amount">
        Заработная плата: {{ beautifyNumber(resume.salary?.amount) }} {{ getCurrency(resume.salary?.currency) }}
      </p>
      <p class="resume-card__total-experience" v-if="totalExperience">
        Опыт работы:
        {{ totalExperience.years }} {{ getAgeCaseWord(totalExperience.years) }} {{ totalExperience.months }}
        {{ getMonthsCaseWord(totalExperience.months) }}
      </p>
      <slot name="work-experience">
        <div class="resume-card__work-experience" v-if="resume.experience?.length > 0">
          <p class="work-experience__title">Последние 3 места работы</p>
          <experience-component
            v-for="(experience, i) in resume.experience.slice(0, 3)"
            :key="i"
            :experience="experience"
          />
        </div>
        <p class="resume-card__nonexistent-work-experience" v-else>Опыт работы не указан</p>
      </slot>
      <slot name="education" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./styles.model.scss";
</style>
