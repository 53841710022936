import { createApp } from "vue"
import "@/app/styles/tailwind.init.css"
import App from "./App.vue"
import router from "@/app/router/index.js"
import store from "./store"
import _ from "lodash"
import BroadcastChanelPlugin from "@/app/plugins/BroadcastChanelPlugin"

import PrimeVue from "primevue/config"
import "primevue/resources/themes/bootstrap4-light-purple/theme.css"
import "primeicons/primeicons.css"
import "primevue/resources/primevue.min.css"
import primevueComponentsInit, { localInit } from "./app/primevue-components.init"

import uiKitsInit from "@/shared/UI-kits/ui-kits.init.js"
import "animate.css"
import "./registerServiceWorker"

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* import specific icons */
import {
  faUserSecret,
  faChartSimple,
  faArrowDownUpAcrossLine,
  faArrowDown19,
  faArrowDown91,
  faArrowUp,
  faBell
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faComments, faUser } from "@fortawesome/free-regular-svg-icons"

const app = createApp(App)

// инициализация пакетов
app.use(store).use(router).use(PrimeVue, { locale: localInit }).use(_).use(BroadcastChanelPlugin)

// инициализация глобальных компонентов
primevueComponentsInit.forEach((component) => {
  app.component(component.name, component.address)
})

uiKitsInit.forEach((component) => {
  app.component(component.name, component.address)
})

library.add(
  // solid
  faUserSecret,
  faChartSimple,
  faArrowDownUpAcrossLine,
  faArrowDown19,
  faArrowDown91,
  faArrowUp,
  faBell,
  // regular
  faComments,
  faUser,
  // brands
  faWhatsapp
)

app.component("font-awesome-icon", FontAwesomeIcon)

// инициализация приложения
app.mount("#app")
