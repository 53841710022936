// функция, которая приводит в нужную форму слово "лет", чтобы отобразить
export const getAgeCaseWord = (age) => {
  if (age != null) {
    let stringifyAge = age + ""
    return stringifyAge.match(/1[0-9]/g) || stringifyAge.match(/[1-9][0]/g) || stringifyAge.match(/[1-9][5-9]/g)
      ? "лет"
      : stringifyAge.match(/[1-9]1/) || stringifyAge.match(/1/)
      ? "год"
      : stringifyAge.match(/[1-9][2-4]/g) || stringifyAge.match(/[2-4]/)
      ? "года"
      : "лет"
  }
}
