export const FieldStore = {
    fields: {
      billingTypes: null,
      statusStaff: null,
      cities: null,
      skills: null,
      departments: null,
      driverLicenseTypes: null,
      addressWorkDetailed: null,
      schedule: null,
      employment: null,
      employers: null,
      salaryCurrency: null,
      professionalRoles: null,
      workPositions: null,
      workers: null,
      statuses: null,
      genders: [
        { id: 1, value: "male", name: "Мужской" },
        { id: 2, value: "female", name: "Женский" }
      ],
      businessTripReadiness: null,
      relocation: null,
      travelTime: null,
      addresses: null
    },
    HHFields: {
      textLogic: null,
      labels: null,
      relocation: null,
      educationLevels: null,
      employment: null,
      experience: null,
      language: null,
      languageLevel: null,
      orderBy: null,
      schedule: null,
      jobSearchStatus: null,
      textFields: null,
      textPeriod: null,
      commentsAccessTypes: null
    },
    AvitoFields: {
      specialization: null,
      nationality: null,
      educationLevel: null,
      gender: null,
      relocation: null,
      drivingExperience: null,
      medicalBook: null,
      resultFields: null,
      billingTypes: null,
      ownTransport: null
    }
  }
  