import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Password from "primevue/password";
import MultiSelect from "primevue/multiselect";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Calendar from "primevue/calendar";
import Textarea from "primevue/textarea";
import RadioButton from "primevue/radiobutton";
import ContextMenu from "primevue/contextmenu";
import Editor from "primevue/editor";
import ImageComponent from "primevue/image";

export default [
  { name: "DropdownComponent", address: Dropdown },
  { name: "InputMask", address: InputMask },
  { name: "CheckBox", address: Checkbox },
  { name: "InputText", address: InputText },
  { name: "InputPassword", address: Password },
  { name: "InputNumber", address: InputNumber },
  { name: "MultiSelect", address: MultiSelect },
  { name: "AccordionComponent", address: Accordion },
  { name: "AccordionTab", address: AccordionTab },
  { name: "CalendarComponent", address: Calendar },
  { name: "TextareaComponent", address: Textarea },
  { name: "RadioButton", address: RadioButton },
  { name: "ContextMenuPrime", address: ContextMenu },
  { name: "EditorComponent", address: Editor },
  { name: "ImageComponent", address: ImageComponent },
];

export const localInit = {
  passwordPrompt: "Введите пароль",
  monthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  monthNamesShort: [
    "Янв",
    "Фев",
    "Март",
    "Апр",
    "Май",
    "Июнь",
    "Июль",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
};
