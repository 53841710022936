import axios from "axios"
import HHApi from "@/shared/API/additional-functional/head-hunter/class-HH"

// базовый конфиг axios для формирования POST запросов
let config = {
  baseURL: process.env.NODE_ENV === "production" ? "/api/" : process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.AuthorizationTokenHr
  }
}

export const instance = axios.create(config)

instance.interceptors.response.use(
  async (responce) => {
    if (responce != null && responce.data.error && responce.data.error.code == -32001) {
      return responce
    }

    // если токен ХХ недействителен, то отправить на страницу авторизации в ХХ
    if (responce != null && responce.data.error && responce.data.error.code == -32002) {
      let request = await HHApi.fetchHHApplicationAcceptUrl().then((data) => data)
      if (request.error === false) {
        return (document.location.href = request.result)
      }
      if (request.error === true || request.code == -32002) {
        return {
          error: true,
          message: responce.data.error.message,
          code: responce.data.error.code
        }
      }
    }

    if (responce != null && responce.data != null && responce.data.error) {
      let error = responce.data.error

      return {
        error: true,
        message: error.message,
        code: error.code
      }
    }

    if (responce != null && responce.data != null && responce.data.result != null && responce.data.result.error) {
      return {
        error: true,
        message: "Произошла ошибка",
        data: responce.data.result.error,
        result: responce.data.result.error
        // неправильно назвала, поэтому надо смотреть где используется дата, а где резалт (в идеале использовать резалт)
      }
    }

    return {
      error: false,
      result: responce.data.result
    }
  },
  (error) => {
    console.log(error)
    if (error != null && error.message && error.code) {
      throw {
        error: true,
        message: error.message,
        code: error.code
      }
    }

    if (error != null && error.name && error.name == "AxiosError") {
      throw {
        error: true,
        message: error.message
      }
    }

    throw {
      error: true,
      data: error
    }
  }
)
