import { UserAPI, setTokens } from "../index"
import router from "@/app/router"

export const refreshAccessToken = async (refreshToken) => {
  try {
    const { result, error, message } = await UserAPI.refreshToken(refreshToken)
    if (error) throw { error, message }
    setTokens({ access: result.access })
    return { result, error, message }
  } catch (error) {
    router.push({ name: "sign-in" })
    return error
  }
}
