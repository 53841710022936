import { noTokenInstance } from "@/shared/API"
import useFetch from "@/app/hooks/use-fetch"
// FIXME перепиши функции авторизации на новый метод класса!!!
export class API {
  /**
   * @param {{method: string, params: ?{}}} options
   */
  constructor(options) {
    this.method = options.method
    this.params = options.params || null
    this.sendRequest = this.sendRequest.bind(this)
  }

  /**
   * геттер для метода
   * @returns {{
   *  jsonrpc: "2.0",
   *  method: string,
   *  params: {},
   *  id: 1
   * }}
   */
  get method() {
    return {
      jsonrpc: "2.0",
      method: this._method,
      params: this._params,
      id: 1
    }
  }
  /**
   * сеттер для метода
   * @param {string} value
   */
  set method(value) {
    this._method = value
  }
  get params() {
    return this._params
  }
  /**
   * сеттер для параметров
   * @param {*} value
   */
  set params(value) {
    this._params = value
  }

  /**
   * запрос с токеном пользователя
   * @returns {Promise}
   */
  async sendRequest() {
    return await useFetch(this.method).then((data) => data)
  }

  /**
   * запрос без токена пользователя
   * @returns {Promise}
   */
  async sendNoTokenRequest() {
    return await noTokenInstance.post(process.env.VUE_APP_BASE_URL, this.method).then((data) => {
      console.log(data)
      return data
    })
  }
}
