import { API } from "@/shared/API"

// это класс с базовыми запросами, которые относятся ко всему проекту и могут быть доступных из любогокласса
export class AppAPI {
  /**
   * запрос на добавление нового файла на сервер
   * @param {{ name: string, format: string, file: string }} param
   * @returns { Promise }
   */
  static async uploadFileToSystem({ name, format, file, isAdditionalFile = false }) {
    let request = new API({
      method: "action.upload_file_to_project",
      params: {
        name,
        format,
        file,
        is_additional_file: isAdditionalFile
      }
    })
    return request.sendRequest()
  }
}
