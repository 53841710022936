import { API } from "@/shared/API"

class VacancyStatisticAPI {
  /**
   * Функция для получения статистики просмотров и откликов по конкретной вакансии за последние 5 дней
   * @param {Number} id id вакансии
   * @returns Массив с объектами: которые содержат: дату, кол-во просмотров, кол-во откликов
   */
  getStatisticsByVacancy(id) {
    const request = new API({ method: "vacancy.get_vacancy_stats_on_hh", params: { vacancy_id: id } })
    return request.sendRequest()
  }

  /**
   * Функция для получения массива с резюме кандидатов, которые откликнулись на вакансию за последнюю неделю
   * @param {Number} id id вакансии
   * @returns Массив с резюме кандидатов
   */
  getStatisticsByVacancyVisitors(id) {
    const request = new API({ method: "vacancy.get_vacancy_visitors_on_hh", params: { vacancy_id: id } })
    return request.sendRequest()
  }

  /**
   * Функция для получения статистики по канбанам вакансий
   * @returns Promise
   */
  getKanbanStatisticVacancies() {
    const request = new API({ method: "vacancy.get_kanban_statistic" })
    return request.sendRequest()
  }
}

export default new VacancyStatisticAPI()
