import { ResumeTagAPI } from "@/entities/resume-tag"
export const ResumeTagActions = {
  //   получеие всех тэгов, которые есть
  async getAllTags({ commit, state }, { forced }) {
    if (state.resumeTags.length === 0 || forced === true) {
      return await ResumeTagAPI.fetchAllTags().then((data) => {
        if (data && data.error === true) throw data.message

        if (data.error === false) {
          forced != true && commit("PUSH_TAGS_IN_STORE", { data: data.result })
          forced === true && commit("REFRESH_TAG_IN_STORE", { data: data.result })
        }
        return data
      })
    }
  },
  // создание нового тэга
  async createNewTag(_, { name, color }) {
    return await ResumeTagAPI.fetchCreateTag({ name, color }).then((data) => {
      return data
    })
  },
  async requestUpdateTag({ getters, commit }, { tagId, name, color }) {
    let allResumes = getters.getResumes
    return await ResumeTagAPI.fetchUpdateTag({ tagId, name, color }).then((data) => {
      if (data && data.error === false) {
        commit("UPDATE_TAG_IN_RESUMES", {
          tag: data.result,
          resumes: allResumes
        })
        commit("UPDATE_TAG_IN_STORE", { tag: data.result })
      }
      return data
    })
  },
  async requestDeleteTagFromResume({ commit }, { tagId, resumeId }) {
    commit("DELETE_TAG_FROM_RESUME", { resumeId, tagId })

    return await ResumeTagAPI.fetchRemoveTagFromResume({
      resumeIds: [resumeId] /* array */,
      tagIds: [tagId] /* array */
    }).then((data) => {
      return data
    })
  },
  async fetchAddTagToResume({ commit, state }, { tagId, resume }) {
    // добавляем тег в резюме
    commit("ADD_TAG_TO_RESUME", {
      resume: resume,
      tagData: state.resumeTags,
      tagId
      // tagData: data.result.success[0].tag,
    })
    // добавляем резюме в стейт, если его нет (проверка уже в мутации)
    commit("ADD_RESUME", resume)
    return await ResumeTagAPI.fetchAddTagToResume({
      resumeIds: [resume.id] /* array */,
      tagIds: [tagId] /* array */
    }).then((data) => {
      console.log(data)
      console.log(resume)
      // if (data && data.error === false && data.result.success.length > 0) {

      // }
      return data
    })
  },
  async requestDeleteTag({ commit }, { tagId }) {
    await ResumeTagAPI.fetchDeleteTag(tagId).then((data) => {
      if (data && data.error === false) {
        commit("DELETE_TAG_EVERYWHERE", { tagId })
      }
      return data
    })
  }
}
