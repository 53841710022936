export default {
  install: (app) => {
    const broadcastChanel = new BroadcastChannel("main-broadcast-chanel");

    broadcastChanel.postMessage({ text: "created" });
    broadcastChanel.onmessage = (event) => {
      console.log(event);
    };
    broadcastChanel.onmessageerror = (error) => {
      new Error("Произошла ошибка с broadcastChanel " + error);
    };

    app.config.globalProperties.$broadcast = broadcastChanel;
  },
};
