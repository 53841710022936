import { VacancyAPI } from "../../index"
import { correctDataModel } from "@/shared/lib/helpers"

export default {
  // получение всех вакансий
  async requestAllVacancys({ commit, state }, { forced, archived, page, perPage }) {
    let archivedToBool = eval(archived)

    if (
      // если размер архивного массива меньше либо равен размеру активного массива (на случай, если перенесли вакансии в архивные)
      (archivedToBool == true &&
        state.vacancys.archived.archivedArr.length <= state.vacancys.active.activeArr.length) ||
      // если размер активного массива меньше либо равен 1 (на случай, если пользователь был на странице какой-то вакансии)
      (archivedToBool == false && state.vacancys.active.activeArr.length <= 1) ||
      // если принудительная загрузка
      forced == true
    ) {
      return await VacancyAPI.fetchAllMyVacancys(archivedToBool, page, perPage).then((data) => {
        if (data && data.error === false) {
          commit("SET_PAGINATION", {
            activeStatus: archivedToBool,
            page: data.result.page,
            maxPage: data.result.pages
          })
          if (data.result.vacancies != null) {
            data.result.vacancies.forEach((vacancy) => {
              commit("ADD_VACANCY", {
                vacancy,
                archivedStatus: eval(archivedToBool)
              })
            })
          }
        }
        commit("FILTER_UNIQ_VACANCYS_BY_ID")
        commit("SORT_VACANCY_BY_NAME")
        return data
      })
    }
  },
  // получение детальной информации по вакансии
  async requestVacancyDetails({ commit }, { vacancyId, archived }) {
    return await VacancyAPI.fetchVacancyDetails(vacancyId).then((data) => {
      console.log(data)
      if (data.error == false) {
        commit("ADD_VACANCY", {
          vacancy: data.result,
          archivedStatus: eval(archived)
        })
        commit("FILTER_UNIQ_VACANCYS_BY_ID")
        commit("SORT_VACANCY_BY_NAME")
      }
      return data
    })
  },
  // получение ступеней для канбана
  requestVacancysSteps: async ({ commit }) => {
    return VacancyAPI.fetchVacancyKanbanSteps().then((data) => {
      if (data.error === false) {
        let newData = []
        for (let key in data.result) {
          newData.push(correctDataModel(key, data.result))
        }
        console.log(newData)
        commit("SET_VACANCYS_STEPS", { data: newData })
      }
      return data
    })
  }
}
