<template>
  <div>
    <notifications-component ref="notificationsComponent" />
    <router-view />
  </div>
</template>

<script>
import { setThemMode } from "@/shared/lib/helpers"
import { defineAsyncComponent, onErrorCaptured, provide, ref, onMounted } from "vue"

export default {
  components: {
    NotificationsComponent: defineAsyncComponent(() =>
      import("@/features/notifications/ui/TheNotificationsComponent.vue")
    )
  },
  setup() {
    let notificationsComponent = ref(null)

    onErrorCaptured((error) => {
      console.log("Ошибка: " + error)
    })

    onMounted(async () => {})

    setThemMode()

    provide("notificationComponent", notificationsComponent) // шеринг компонента для оповещений

    return { notificationsComponent }
  }
}
</script>

<style lang="scss">
// импорт базовых стилей
@import "@/app/styles/_variables.scss";
@import "@/app/styles/tailwind.init.css";
@import "@/app/styles/fonts.scss";
@import "@/app/styles/style.scss";
@import "@/app/styles/normalize.scss";

@import "@/app/styles/UI-kits/primevue-normalize.scss";
</style>
