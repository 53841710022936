export default {
    getVacancys: (store) => {
      return store.vacancys;
    },
    getActiveVacancys: (store) => {
      return store.vacancys.active.activeArr;
    },
    getVacancyById: (store) => (id, archived) => {
      let archivedToBool = eval(archived);
      if (archivedToBool == false) {
        return store.vacancys.active.activeArr.find((el) => el.id == id);
      }
      return store.vacancys.archived.archivedArr.find((el) => el.id == id);
    },
    // возвращает все поля у вакансий
    getVacancyFields: (store) => {
      return store.vacancyFields;
    },
    // возвращает всех работадателей
    getVacancyEmployers: (store) => {
      return store.vacancyFields.employers;
    },
    // возвращает ступени для канбана
    getVacancysSteps: (store) => {
      return store.vacancysSteps;
    },
  };
  