export const ResumeTagMutations = {
  UPDATE_TAG_IN_RESUMES(store, { tag, resumes }) {
    console.log(123)
    if (resumes != null && resumes.length > 0) {
      resumes.forEach((resume) => {
        let resumeTags = resume.tag
        let tagIndex = resumeTags.findIndex((el) => el.id === tag.id)
        console.log(tagIndex)

        if (tagIndex > -1) {
          resumeTags.splice(tagIndex, 1, tag)
        }
      })
    }
  },
  UPDATE_TAG_IN_STORE(store, { tag }) {
    let tags = store.resumeTags
    let tagIndex = tags.findIndex((el) => el.id === tag.id)
    if (tagIndex > -1) {
      tags.splice(tagIndex, 1, tag)
    }
  },
  PUSH_TAGS_IN_STORE(store, { data }) {
    store.resumeTags.push(...data)
  },
  DELETE_TAG_EVERYWHERE(store, { tagId }) {
    let resumes = store.resumes.resumeArray
    resumes.forEach((resume) => {
      let tagIndex = resume.tag.findIndex((tag) => tag.id == tagId)
      if (tagIndex > -1) {
        resume.tag.splice(tagIndex, 1)
      }
    })
  },
  // удаление тега из резюме
  DELETE_TAG_FROM_RESUME(store, { resumeId, tagId }) {
    // this.commit("SORT_UNIQ_RESUMES");
    let storeResumes = store.resumes.resumeArray
    // получение вакансии с заданным id
    let resume = storeResumes.find((resume) => resume.id === resumeId)

    // удаление тэга если резюме найдено и ткой тэг есть в вакансии
    if (resume != null) {
      resume.tag = resume.tag.filter((el) => el.id != tagId)
    }
  },
  ADD_TAG_TO_RESUME(store, { resume, tagData, tagId }) {
    let storeResumes = store.resumes.resumeArray
    // получение вакансии с заданным id
    let findResume = storeResumes.find((el) => el.id === resume.id)
    let tag = tagData.find((el) => el.id == tagId) // тег в стейте тегов
    let resumeHasTag = findResume != null ? findResume.tag.map((el) => el.id).includes(tagId) : false // проверка на сущестование тега в резюме
    console.log(findResume)

    if (findResume != null && tag != null && resumeHasTag === false) {
      resume.tag = [...resume.tag, tag]
    }
  },
  REFRESH_TAG_IN_STORE(store, { data }) {
    store.resumeTags = data
  },
  DELETE_TAG_FROM_STORE(store, { tagId }) {
    store.resumeTags = store.resumeTags.filter((tag) => tag.id != tagId)
  }
}
