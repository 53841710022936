import { API } from "@/shared/API"
import { correctDataModel } from "@/shared/lib/helpers"
import store from "@/store"
import AvitoFieldAPI from "./avito.js"

export { AvitoFieldAPI }
export class FieldAPI {
  /**
   * функция для добавления данных полей в стор
   * @param {{
   *   data: { error: boolean, result: Array },
   *   hasMethod: boolean,
   *   field: string,
   *   fieldObject: string
   * }} param
   * hasMethod - для определения нужно форматировать данные или нет,
   * field - имя поля в сторе, в которое нужно добавить данные,
   * fieldObject - имя объекта в сторе, в котором находится field
   */
  static setDataFields({ data, hasMethod, field, fieldObject }) {
    if (data.error === false) {
      // задаю значения для полей, у которых модель данных неудобна
      if (hasMethod) {
        let result = []
        for (let key in data.result) {
          result.push(correctDataModel(key, data.result))
        }
        store.commit("SET_FIELD", {
          field: field,
          data: result,
          fieldObject
        })
      } else {
        if (field == "addresses") {
          store.commit("SET_FIELD", {
            field: field,
            data: data.result.items,
            fieldObject
          })
        } else {
          store.commit("SET_FIELD", {
            field: field,
            data: data.result,
            fieldObject
          })
        }
      }
    }
  }
  /**
   * функция для получения значений полей
   */
  static async fetchFields() {
    let storeFields = [
      "statusStaff",
      "cities",
      "skills",
      "departments",
      "driverLicenseTypes",
      "addressWorkDetailed",
      "salaryCurrency",
      "professionalRoles",
      "employers",
      "workPositions",
      "workers",
      "statuses",
      "addresses"
    ]
    let editFields = ["vacancy.get_salary_currency", "vacancy.get_vacancy_status_staff", "vacancy.get_status"]
    let methods = [
      "vacancy.get_vacancy_status_staff",
      "get_cities",
      "get_skills",
      "get_departments",
      "get_driver_license_types",
      "get_address_work_detailed",
      "vacancy.get_salary_currency",
      "get_professional_roles",
      "get_employers",
      "core.get_position_workers",
      "core.get_workers",
      "vacancy.get_status",
      "get_employer_addresses_from_hh"
    ]
    for (let method of methods) {
      let request = new API({
        method: method
      })

      let index = methods.findIndex((el) => el == method)

      if (store.getters.getFields[storeFields[index]] == null) {
        await request.sendRequest().then((data) => {
          this.setDataFields({
            data: data,
            hasMethod: editFields.includes(method),
            field: storeFields[index]
          })
        })
      }
    }
  }
  /**
   * метод для получения ствтусов вакансий
   * @returns { Promise }
   */
  static async fetchStatuses() {
    let request = new API({
      method: "vacancy.get_status"
    })
    // FIXME вынеси логику по занесению в стор
    if (store.getters.getFields.statuses == null) {
      return await request.sendRequest().then((data) => {
        this.setDataFields({
          data: data,
          hasMethod: true,
          field: "statuses"
        })
      })
    }
  }
  /**
   * метод для получения валют
   * @returns { Promise }
   */
  static async fetchSalaryCurrency() {
    let request = new API({
      method: "vacancy.get_salary_currency"
    })
    // FIXME вынеси логику по занесению в стор
    if (store.getters.getFields.statuses == null) {
      return await request.sendRequest().then((data) => {
        this.setDataFields({
          data: data,
          hasMethod: true,
          field: "salaryCurrency"
        })
      })
    }
  }
  /**
   * метод для получения ствтусов вакансий
   * @returns { Promise }
   */
  static async fetchPositionWorkers() {
    let request = new API({
      method: "core.get_position_workers"
    })
    // FIXME вынеси логику по занесению в стор
    if (store.getters.getFields.statuses == null) {
      return await request.sendNoTokenRequest().then((data) => {
        this.setDataFields({
          data: data,
          hasMethod: false,
          field: "workPositions"
        })
      })
    }
  }
  /**
   * метод для получения работодателей
   * @returns { Promise }
   */
  static async fetchEmployers() {
    let request = new API({
      method: "get_employers"
    })
    // FIXME вынеси логику по занесению в стор
    if (store.getters.getFields.statuses == null) {
      return await request.sendRequest().then((data) =>
        this.setDataFields({
          data: data,
          hasMethod: false,
          field: "employers"
        })
      )
    }
  }

  static async fetchAllFields() {
    let request = new API({
      method: "core.get_dictionaries"
    })
    const { result } = await request
      .sendRequest()
      .then((data) => {
        if (data.error === true) throw data
        return data
      })
      .catch((error) => {
        throw error
      })

    const { avito, hh } = result,
      {
        applicant_comment_access_type,
        business_trip_readiness,
        education_level,
        employment,
        job_search_status,
        label,
        experience,
        language,
        language_level,
        resume_relocation,
        resume_search_relocation,
        schedule,
        text_logic,
        text_field,
        text_period,
        travel_time,
        vacancy_billing_types,
        vacancy_search_order_by
      } = hh,
      {
        resume_driving_experience,
        resume_education_level,
        resume_medical_book,
        resume_nationality,
        resume_own_transport,
        resume_relocation: avito_resume_relocation,
        resume_result_fields,
        resume_specialization,
        vacancy_billing_types: avito_vacancy_billing_types
      } = avito,
      HHFields = {
        commentsAccessTypes: applicant_comment_access_type,
        educationLevels: education_level,
        employment,
        experience,
        jobSearchStatus: job_search_status,
        labels: label,
        language,
        languageLevel: language_level,
        relocation: resume_search_relocation,
        schedule,
        textLogic: text_logic,
        textFields: text_field,
        textPeriod: text_period,
        orderBy: vacancy_search_order_by
      },
      fields = {
        businessTripReadiness: business_trip_readiness,
        relocation: resume_relocation,
        travelTime: travel_time,
        employment,
        schedule,
        billingTypes: vacancy_billing_types
      },
      avitoFields = {
        specialization: resume_specialization,
        nationality: resume_nationality,
        educationLevel: resume_education_level,
        relocation: avito_resume_relocation,
        drivingExperience: resume_driving_experience,
        medicalBook: resume_medical_book,
        resultFields: resume_result_fields,
        billingTypes: avito_vacancy_billing_types,
        ownTransport: resume_own_transport
      }

    Object.getOwnPropertyNames(HHFields).forEach((key) => {
      this.setDataFields({
        data: { error: false, result: HHFields[key] },
        hasMethod: true,
        field: key,
        fieldObject: "HHFields"
      })
    })
    Object.getOwnPropertyNames(fields).forEach((key) => {
      this.setDataFields({
        data: { error: false, result: fields[key] },
        hasMethod: true,
        field: key,
        fieldObject: "fields"
      })
    })
    Object.getOwnPropertyNames(avitoFields).forEach((key) => {
      this.setDataFields({
        data: { error: false, result: avitoFields[key] },
        hasMethod: true,
        field: key,
        fieldObject: "AvitoFields"
      })
    })

    return result
  }
}
