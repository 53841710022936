// возвращает полное имя из объекта
export function returnName(val) {
  if (
    (val != null &&
      val.last_name == null &&
      val.first_name == null &&
      val.patronymic == null &&
      val.middle_name == null) ||
    val == null
  ) {
    return null;
  }

  return `${val.last_name != null ? val.last_name : ""}${
    val.first_name != null ? " " + val.first_name : ""
  }${
    val.patronymic != null
      ? " " + val.patronymic
      : val.middle_name != null
      ? " " + val.middle_name
      : ""
  }`;
}
// возвращает фамилию и имя с фамилией первые буквы
export function returnShortName(val) {
  return `${val.last_name != null ? val.last_name : ""} ${
    val.first_name != null ? val.first_name[0] + "." : ""
  } ${
    val.patronymic != null
      ? val.patronymic[0] + "."
      : val.middle_name != null
      ? val.middle_name[0] + "."
      : ""
  }`;
}
