export default {
  // добавление всех даннных
  ADD_ALL_RESPONSES(store, { data, vacancy }) {
    if (data != null && data.length > 0) {
      data.forEach((collection) => {
        collection.responses = collection.objects;
        delete collection.objects;
      });
      vacancy.reponses_collections = data;
      console.log(vacancy.reponses_collections);
    }
  },
  // добавление коллекций откликов в вакансию
  ADD_RESPONSES_COLLECTIONS(store, { responsesCollections, vacancy }) {
    if (vacancy.reponses_collections == null) {
      vacancy.reponses_collections = responsesCollections;
    }

    if (vacancy.reponses_collections != null) {
      let vacancyResponsesCollectionsIds = vacancy.reponses_collections.map(
        (collection) => collection.id
      );
      responsesCollections.forEach((collection) => {
        if (!vacancyResponsesCollectionsIds.includes(collection.id)) {
          vacancy.reponses_collections.push(collection);
        }
      });
    }
  },
  // добавление откликОВ в коллекцию
  ADD_RESPONSES_TO_COLLECTION(state, { collection, responsesData: responses }) {
    if (collection != null && collection.responses == null) {
      collection.responses = responses;
    }

    if (collection != null && collection.responses != null) {
      responses.forEach((response) => {
        this.commit("ADD_RESPONSE_TO_COLLECTION", {
          responsesOfCollection: collection.responses,
          response,
        });
      });
    }
  },
  // добавление откликА в коллекцию
  ADD_RESPONSE_TO_COLLECTION(store, { responsesOfCollection, response }) {
    let responseIndex = responsesOfCollection.findIndex(
      (el) => el.id == response.id
    );
    if (responseIndex > -1) {
      responsesOfCollection.splice(responseIndex, 1, response);
    } else {
      responsesOfCollection.unshift(response);
    }
  },
  // установление ключа "сохранено" для откликов
  SET_SAVED_RESPONSES(store, { vacancy, resumes }) {
    if (vacancy != null && vacancy.reponses_collections != null) {
      vacancy.reponses_collections.forEach((collection) => {
        if (collection.responses) {
          collection.responses.forEach((response) => {
            let hasResumeInResponse =
              response.resume != null
                ? resumes
                    .map((el) => el.hh_resume_id)
                    .includes(response.resume.id)
                : null;

            if (hasResumeInResponse != null) {
              response.saved = hasResumeInResponse;
            }
          });
        }
      });
    }
  },
  // проверка все ли отклики в коллекции были сохранены
  SET_IS_SAVED_ALL_IN_COLLECTION(store, collection) {
    if (collection != null && collection.responses != null) {
      let countSavedResponse = collection.responses.filter(
        (el) => el.saved == true
      );
      let filteredResponses = collection.responses.filter(
        (el) => el.resume != null
      );

      if (filteredResponses.length == 0) {
        collection.savedAll = null;
        return null;
      } else if (
        collection.responses.filter((el) => el.resume != null).length ==
        countSavedResponse.length
      ) {
        collection.savedAll = true;
        return true;
      } else {
        collection.savedAll = false;
        return false;
      }
    }
    console.log(collection);
  },
  // установление значения has_updates для отклика
  SET_RESPONSE_HAS_UPDATES_STATUS(
    store,
    {
      vacancy,
      collectionId,
      responseId,
      statusValue = false /* @type boolean */,
    }
  ) {
    let collection = this.getters.getCollectionById({ vacancy, collectionId });
    let response =
      collection != null
        ? collection.responses.find((el) => el.id === responseId)
        : null;
    if (response != null) {
      response.has_updates = statusValue;
    }
  },
};
