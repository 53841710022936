import { API } from "@/shared/API"

export default class HHResponsesAPI {
  constructor() {}

  //   static async fetch() {
  //     let request = new API({
  //       method: "",
  //       params: {},
  //     });
  //   }

  // запрос на получение списка коллекций
  static async fetchGetCollectionsByVacancy(vacancyHhId) {
    let request = new API({
      method: "vacancy.get_collections_by_vacancy",
      params: {
        vacancy_id: vacancyHhId
      }
    })
    return await request.sendRequest()
  }

  static async fetchGetNegotiationsByCollection(url) {
    let request = new API({
      method: "vacancy.get_negotiations_by_collection",
      params: {
        url
      }
    })
    return await request.sendRequest()
  }

  // запрос на получение всех откликов сразу
  static async fetchGetAllRespnsesOfVacancy(vacancyId) {
    let request = new API({
      method: "vacancy.get_all_negotiations_by_vacancy",
      params: {
        vacancy_id: vacancyId
      }
    })
    return await request.sendRequest()
  }

  // запрос для получения всех вакансий, с которыми можно сделать связь с резюме
  static async fetchGetVacancysApplicableToResume(employerId /* @value employer hh id */, resumeId /* resume hh id */) {
    let request = new API({
      method: "vacancy.get_employer_vacancies_applicable_to_resume",
      params: { employer_hh_id: employerId, resume_hh_id: resumeId }
    })

    return await request.sendRequest()
  }

  // запрос на добавление связи между вакансией и резюме ввиде отклика
  static async fetchInviteResumeToVacancy({ url, message, sendSms, addressId, vacancyId, resumeId }) {
    let request = new API({
      method: "vacancy.invite_candidate_for_vacancy",
      params: {
        url,
        message,
        send_sms: sendSms,
        address_id: addressId,
        vacancy_hh_id: vacancyId,
        resume_hh_id: resumeId
      }
    })
    return await request.sendRequest()
  }

  static async fetchGetResponseMessage({ vacancyId, state, candidateName }) {
    let request = new API({
      method: "vacancy.create_negotiation_message",
      params: {
        vacancy_id: vacancyId,
        candidate_name: candidateName,
        state
      }
    })
    return await request.sendRequest()
  }
}
