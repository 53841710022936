import { createStore } from "vuex"

// пользователь
import { ViewerActions, ViewerGetters, ViewerMutations, ViewerStore } from "@/entities/viewer"

// поля для заполнения
import { FieldGetters, FieldMutations, FieldStore } from "@/entities/field"
// резюме
import { ResumeActions, ResumeGetters, ResumeMutations, ResumeStore } from "@/entities/resume"
// теги резюме
import { ResumeTagActions, ResumeTagGetters, ResumeTagMutations, ResumeTagStore } from "@/entities/resume-tag"
// вакансии
import { VacancyActions, VacancyGetters, VacancyMutations, VacancyStore } from "@/entities/vacancy"
// отклики
import vacancysResponsesActions from "./user-modules/hrs/vacancys-responses/vacancys-responses-actions"
import vacancysResponsesGetters from "./user-modules/hrs/vacancys-responses/vacancys-responses-getters"
import vacancysResponsesMutations from "./user-modules/hrs/vacancys-responses/vacancys-responses-mutations"

export const actions = {
  ...ViewerActions,
  ...ResumeActions,
  ...ResumeTagActions,
  ...VacancyActions,
  ...vacancysResponsesActions
}
export const getters = {
  ...FieldGetters,
  ...ViewerGetters,
  ...ResumeGetters,
  ...ResumeTagGetters,
  ...VacancyGetters,
  ...vacancysResponsesGetters
}
export const mutations = {
  ...FieldMutations,
  ...ViewerMutations,
  ...ResumeMutations,
  ...ResumeTagMutations,
  ...VacancyMutations,
  ...vacancysResponsesMutations
}
export const state = {
  ...FieldStore,
  ...ViewerStore,
  ...ResumeStore,
  ...ResumeTagStore,
  ...VacancyStore
}

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {}
})
