export function setValuesInVacancyForm({ form, values, options }) {
  form.mainTitle = values.name
  form.emploer = values.employer ? values.employer : null
  form.startNumberPositions = values.start_count
  form.professionalRoles = values.professional_roles ? values.professional_roles : null
  form.workExperienceYears = values.work_experience_in_years
  form.driverLicense = values.driver_license_types
  form.linkCareerPage = values.link_career_page
  form.linkTest = values.link_test
  form.comment = values.comment
  form.keySkils = values.key_skills
  form.status = findObjectContainsValue(options.statuses, values.status)
  form.salaryMax = values.salary_max
  form.salaryMin = values.salary_min
  form.recruiter = values.recruiter
  form.publishWebsite = values.publish_website != null ? values.publish_website : false
  form.dopRecruiters = values.dop_recruiter
  form.jobPlace = values.address_work != null && values.address_work != null ? values.address_work : null
  form.workSchedule =
    options.schedule != null
      ? options.schedule.find((el) => {
          if (el.name == values.schedule) {
            console.log(el)
            return el
          }
        })
      : null
  form.billingType = findObjectContainsValue(options.billingTypes, values.billing_type)
  form.hasOutsideCustomer = values.outside_customer
  form.employment = findObjectContainsValue(options.employment, values.employment)
  form.outsideCustomerName = values.outside_customer_name
  form.salaryCurrency = options.salaryCurrency
    ? findObjectContainsValue(options.salaryCurrency, values.salary_currency)
    : null
  form.statusStaff = findObjectContainsValue(options.statusStaff, values.status_staff)
  form.hhDescription = values.hh_description
  form.detailAddress =
    values.detail_address != null && values.detail_address.id != null && options.addressWorkDetailed
      ? options.addressWorkDetailed.find((el) => el.id == values.detail_address.id)
      : null
  // statusStaff, status_staff
  console.log(options)
  console.log(values)
}

function findObjectContainsValue(option, value) {
  if (option != null) {
    return option.find((el) => el.name == value)
  }
  return null
}
