// const dynamicCacheName = 'd-vue-app-v1';

window.addEventListener("load", async () => {
  if ("serviceWorker" in navigator) {
    try {
      await navigator.serviceWorker.register(`${process.env.BASE_URL}sw.js`)
      console.log("SW loaded")
    } catch {
      console.log("SW error")
    }
  }
})
