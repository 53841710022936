let appElement = document.querySelector("#app")
export let setThemMode = () => {
  if (localStorage.themeMode) {
    if (localStorage.themeMode == "true") {
      appElement.classList.add("light")
      appElement.classList.remove("dark")
    }
    if (localStorage.themeMode == "false") {
      appElement.classList.add("dark")
      appElement.classList.remove("light")
    }
  } else {
    localStorage.themeMode = true
    if (localStorage.themeMode == "true") appElement != null ? appElement.classList.add("light") : null
  }
}

export let resetThemMode = () => {
  localStorage.themeMode = !JSON.parse(localStorage.themeMode)
  setThemMode()
}
