export default [
  {
    path: "",
    name: "hr-home",
    meta: { requiresAuth: true, title: "Главная" },
    component: () => import(/* webpackChunkName: "user-hr-home" */ "@/views/users/hrs/HrHomeView.vue")
  },
  {
    path: "vacancys",
    name: "hr-vacancys-paths",
    alias: "/hr-vacancys",
    meta: { requiresAuth: true, title: "Вакансии" },
    component: () =>
      import(/* webpackChunkName: "user-hr-vacancys" */ "@/views/users/hrs/vacancys-views/HrVacancysView.vue")
  },
  {
    path: "vacancy/:id",
    name: "hr-vacancy",
    alias: "/hr-vacancy/:id",
    meta: { requiresAuth: true, title: null },
    component: () =>
      import(/* webpackChunkName: "user-hr-vacancy" */ "@/views/users/hrs/vacancys-views/HrVacancyView.vue")
  },
  {
    path: "edit-vacancy/:id",
    name: "hr-edit-vacancy",
    alias: "/hr-vacancy/:id",
    meta: { requiresAuth: true, title: null },
    component: () =>
      import(
        /* webpackChunkName: "user-hr-edit-vacancy" */ "@/views/users/hrs/vacancys-views/HrVacancysEditVacancyView.vue"
      )
  },
  {
    path: "vacancys/kanban/:id",
    alias: "/hr-kanban/:id",
    name: "hr-vacancys-kanban",
    meta: { requiresAuth: true, title: "Канбан" },
    component: () =>
      import(
        /* webpackChunkName: "user-hr-vacancys-kanban" */ "@/views/users/hrs/vacancys-views/HrVacancysKanbanView.vue"
      )
  },
  {
    path: "vacancys/create",
    name: "hr-create-vacancy",
    alias: "/hr-create-vacancy",
    meta: { requiresAuth: true, title: "Создание вакансии" },
    component: () =>
      import(
        /* webpackChunkName: "user-hr-vacancys-create" */ "@/views/users/hrs/vacancys-views/HrVacancysCreateVacancyView.vue"
      )
  },
  {
    path: "vacancys/edit/:id",
    alias: "/hr-edit-vacancy/:id",
    name: "hr-edit-vacancy",
    meta: { requiresAuth: true, title: "Редактирование вакансии" },
    component: () =>
      import(
        /* webpackChunkName: "user-hr-vacancys-edit" */ "@/views/users/hrs/vacancys-views/HrVacancysEditVacancyView.vue"
      )
  },
  {
    path: "resumes",
    name: "hr-resumes",
    meta: { requiresAuth: true, title: "Резюме" },
    component: () =>
      import(/* webpackChunkName: "user-hr-resumes" */ "@/views/users/hrs/resumes-views/HrResumesView.vue"),
    children: [
      {
        path: ":id",
        name: "hr-resume-page",
        component: () =>
          import(/* webpackChunkName: "user-hr-resumes" */ "@/views/users/hrs/resumes-views/HrResumeView.vue")
      }
    ]
  },
  {
    path: "resumes/create",
    name: "hr-create-resumes",
    meta: { requiresAuth: true, title: "Создание резюме" },
    component: () =>
      import(/* webpackChunkName: "user-hr-resumes-create" */ "@/views/users/hrs/resumes-views/HrResumesCreateView.vue")
  },
  {
    path: "resumes/edit",
    name: "hr-edit-resume",
    meta: { requiresAuth: true, title: "Редактирование резюме" },
    component: () =>
      import(/* webpackChunkName: "user-hr-resumes-edit" */ "@/views/users/hrs/resumes-views/HrResumesEditView.vue")
  },
  {
    path: "resumes/find",
    name: "hr-find-resumes",
    alias: "/hr-find-resumes",
    meta: { requiresAuth: true, title: "Поиск резюме" },
    component: () =>
      import(/* webpackChunkName: "user-hr-resumes-find" */ "@/views/users/hrs/resumes-views/HrResumesFindView.vue")
  },
  {
    path: "download-from-pdf",
    name: "download-from-pdf",
    meta: { requiresAuth: true, title: "Загрузка из PDF" },
    component: () =>
      import(
        /* webpackChunkName: "download-from-pdf" */ "@/views/users/hrs/resumes-views/HrResumesUploadFromPDFView.vue"
      )
  },
  // {
  //   path: "responses",
  //   alias: "/responses",
  //   name: "responses",
  //   meta: { requiresAuth: true, title: "Отклики" },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "responses" */ "@/views/users/hrs/responses-views/HrResponsesView.vue"
  //     ),
  // },
  {
    path: "vacancy-responses/:id",
    alias: "/vacancy-responses/:id",
    name: "vacancy-responses",
    meta: { requiresAuth: true, title: "Отклики" },
    component: () =>
      import(
        /* webpackChunkName: "vacancys-responses" */ "@/views/users/hrs/responses-views/HrVacancyResponsesView.vue"
      )
  },
  {
    path: "vacancy-response",
    alias: "/vacancy-response",
    name: "vacancy-response",
    meta: { requiresAuth: true, title: "" },
    component: () =>
      import(/* webpackChunkName: "vacancys-response" */ "@/views/users/hrs/responses-views/HrVacancyResponseView.vue")
  }
]
