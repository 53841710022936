export function convertVacancyFormData(data, vacancyId) {
  return {
    id: vacancyId,
    name: data.mainTitle,
    recruterId: data.recruiter != null ? data.recruiter.id : null,
    dopRecruters:
      data.dopRecruiters != null ? data.dopRecruiters.map((el) => el.id) : null,
    // observers: data,
    // managers: data,
    statusStaff: data.statusStaff != null ? data.statusStaff.type : null,
    billingType: data.billingType != null ? data.billingType.type : null,
    schedule: data.workSchedule != null ? data.workSchedule.type : null,
    employment: data.employment != null ? data.employment.type : null,
    salaryCurrency:
      data.salaryCurrency != null ? data.salaryCurrency.type : null,
    status: data.status != null ? data.status.type : null,
    addressWorkId: data.jobPlace != null ? data.jobPlace.id : null,
    employer: data.emploer != null ? data.emploer.id : null,
    professionalRoles:
      data.professionalRoles != null
        ? data.professionalRoles.map((el) => el.id)
        : null,
    startCount: data.startNumberPositions,
    driverLicenseTypes:
      data.driverLicense != null ? data.driverLicense.map((el) => el.id) : null,
    publishWebsite: data.publishWebsite,
    workExperienceInYears: data.workExperienceYears,
    salaryMin: data.salaryMin,
    salaryMax: data.salaryMax,
    linkCareerPage: data.linkCareerPage,
    linkTest: data.linkTest,
    comment: data.comment,
    keySkills: data.keySkils != null ? data.keySkils.map((el) => el.id) : null,
    outsideCustomer: data.hasOutsideCustomer,
    outsideCustomerName: data.outsideCustomerName,
    hhDescription: data.hhDescription != null ? data.hhDescription : null,
    detailAddress: data.detailAddress != null ? data.detailAddress.id : null,
  };
}
