import HHApi from "@/shared/API/additional-functional/head-hunter/class-HH.js";
import router from "../index.js";

export default function ubdateHHTokenIfUrlHaveCode() {
  router.beforeEach(async (to) => {
    if (to != null && to.query != null && to.query.code) {
      await HHApi.fetchUpdateHHTokenByCode(to.query.code).then((data) => {
        if (data && data.error === false) router.push({ path: "/" });
      });
    }
  });
}
