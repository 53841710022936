import { API } from "@/shared/API"

export class UserAPI {
  /**
   * запрос на активацию почты
   * @param {{ uidb64: string, token: string, action: string }} param
   * @returns { Promise }
   */
  static activateEmail({ uidb64, token, action }) {
    let request = new API({
      method: "authentication.email_confirmation",
      params: {
        action,
        token,
        uidb64
      }
    })
    return request.sendNoTokenRequest()
  }

  static getTokens({ login, password }) {
    let request = new API({
      method: "authentication.token",
      params: {
        username: login,
        password: password
      }
    })
    return request.sendNoTokenRequest()
  }

  static refreshToken(refreshToken) {
    const request = new API({
      method: "authentication.refresh",
      params: {
        refresh: refreshToken
      }
    })
    return request.sendNoTokenRequest()
  }
}
