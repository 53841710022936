import _ from "lodash"

export default {
  SET_PAGINATION(store, { activeStatus, page, maxPage }) {
    // установка количества страниц для пагинации
    store.vacancys[activeStatus ? "archived" : "active"].page = page
    store.vacancys[activeStatus ? "archived" : "active"].maxPageCount = maxPage
  },
  // очищение стейта вакансий от повторок
  FILTER_UNIQ_VACANCYS_BY_ID(store) {
    store.vacancys.active.activeArr = _.uniqBy(
      _.sortedUniqBy(store.vacancys.active.activeArr, (el) => el.id),
      (el) => el.id
    )

    store.vacancys.archived.archivedArr = _.uniqBy(
      _.sortedUniqBy(store.vacancys.archived.archivedArr, (el) => el.id),
      (el) => el.id
    )
  },
  SORT_VACANCY_BY_NAME(store) {
    store.vacancys.active.activeArr = _.sortBy(store.vacancys.active.activeArr, (value) => value.name)
    store.vacancys.archived.archivedArr = _.sortBy(store.vacancys.archived.archivedArr, (value) => value.name)
  },
  // удаление архивных вакансий из стейта
  DELETE_ARCHIVED_VACANCYS(store, ids) {
    let archiveVacancys = store.vacancys.archived.archivedArr
    ids.forEach((id) => {
      // фильрация массива с архивными вакансиями на наличие вакансий с заданными IDs
      let filteredVacancys = archiveVacancys.filter((el) => el.id != id)
      store.vacancys.archived.archivedArr = [...filteredVacancys]
    })
    return archiveVacancys
  },
  ADD_VACANCY(store, { vacancy, archivedStatus /* type: bool */ }) {
    // prettier-ignore
    let vacancysStore =
      store.vacancys[archivedStatus ? "archived" : "active"][archivedStatus ? "archivedArr" : "activeArr"];

    let vacancyIndex =
      vacancysStore != null && vacancy.archived === archivedStatus && vacancy.archived !== null
        ? vacancysStore.findIndex((el) => el.id == vacancy.id)
        : null

    if (vacancyIndex != null && vacancyIndex <= -1) {
      vacancysStore.push(vacancy)
    }
    if (vacancyIndex > -1 && vacancyIndex != null) {
      let vacancyKeys = Object.getOwnPropertyNames(vacancy)
      vacancyKeys.forEach((key) => {
        vacancysStore[vacancyIndex][key] = vacancy[key]
      })
    }
  },
  // изменение поля вакансии
  SET_VACANCY_FIELD(store, { field, data }) {
    store.vacancyFields[field] = data
  },
  SET_VACANCYS_STEPS(store, { data }) {
    data.forEach((el) => {
      let stepIndex = store.vacancysSteps.findIndex((elem) => elem.type === el.type)
      if (stepIndex == -1) {
        store.vacancysSteps.push(el)
      }
    })
  },
  // перемещение активных вакансий в архивные
  SET_ACTIVE_VACANCYS_TO_ARCHIVED(store, { vacancysIds }) {
    let activeVacancys = store.vacancys.active.activeArr
    let archivedVacancys = store.vacancys.archived.archivedArr
    let acc = []

    vacancysIds.forEach((el) => {
      // prettier-ignore
      let vacancyIndex = activeVacancys.findIndex((vacancy) => vacancy.id == el);
      if (vacancyIndex != -1) {
        activeVacancys[vacancyIndex].archived = true
        acc.push(activeVacancys.splice(vacancyIndex, 1))
      }
    })

    archivedVacancys.unshift(...acc.flat())
    archivedVacancys.sort()
  }
}
