<script>
import { reactive, ref } from "vue"
import { removeContextMenu } from "@/shared/lib/helpers"

export default {
  setup() {
    let contextMenu = ref(null),
      position = reactive({ x: null, y: null })

    return {
      contextMenu,
      position,
      removeContextMenu
    }
  }
}
</script>

<template>
  <div
    @mouseleave="removeContextMenu($event.target)"
    class="context-menu animate__animated animate__fadeIn animate__faster"
    ref="contextMenu"
  >
    <div class="context-menu__container">
      <ul class="context-menu__menu-list">
        <slot name="menu-items"></slot>
        <!-- <li class="menu-list__item"></li> -->
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/app/styles/UI-kits/menus/context-menu.scss";
</style>
