import { API } from "@/shared/API"

export class ResumeTagAPI {
  /**
   * получние всех доступных тэгов
   * @returns {Promise}
   */
  static fetchAllTags() {
    let request = new API({
      method: "candidate.get_tags"
    })
    return request.sendRequest()
  }
  /**
   * создание нового тега
   * @param {{ name: string, color: ?string }} param
   * @returns { Promise }
   */
  static fetchCreateTag({ name, color }) {
    let request = new API({
      method: "candidate.tag_create",
      params: {
        name,
        color
      }
    })
    return request.sendRequest()
  }
  /**
   * удаление тега из системы
   * @param {string | number} tagId
   * @returns {Promise}
   */
  static fetchDeleteTag(tagId) {
    let request = new API({
      method: "candidate.tag_delete",
      params: {
        tag_id: tagId
      }
    })
    return request.sendRequest()
  }
  /**
   * добавление тега в резюме
   * @param {{ resumeIds: string[] | number[], tagIds: string[] | number[] }} param
   * @returns {Promise}
   */
  static fetchAddTagToResume({ resumeIds, tagIds }) {
    let request = new API({
      method: "candidate.add_tags_to_resumes",
      params: {
        tag_ids: tagIds,
        resume_ids: resumeIds
      }
    })
    return request.sendRequest()
  }
  /**
   * удаление тэгов из резюме
   * @param {{ resumeIds: string[] | number[], tagIds: string[] | number[] }} param
   * @returns {Promise}
   */
  static fetchRemoveTagFromResume({ resumeIds, tagIds }) {
    let request = new API({
      method: "candidate.remove_tags_from_resumes",
      params: {
        tag_ids: tagIds,
        resume_ids: resumeIds
      }
    })
    return request.sendRequest()
  }
  /**
   * обновление информации по тегу
   * @param {{tagId: string | number, name: string, color: ?string}} param
   * @returns {Promise}
   */
  static fetchUpdateTag({ tagId, name, color }) {
    let request = new API({
      method: "candidate.tag_update",
      params: {
        tag_id: tagId,
        name,
        color
      }
    })
    return request.sendRequest()
  }
}
