import LoadingButton from "@/shared/UI-kits/LoadingButton.vue";
import SpinerComponent from "@/shared/UI-kits/SpinerComponent";
import DefaultPopUp from "@/shared/UI-kits/pop-ups/DefaultPopUpComponent.vue";
import ResultPopUp from "@/shared/UI-kits/pop-ups/ResultPopUpComponent.vue";
import DefaultFormLabel from "@/shared/UI-kits/forms/DefaultFormLabel.vue";
import DefaultForm from "@/shared/UI-kits/forms/DefaultFormComponent.vue";
import IconSpan from "@/shared/UI-kits/IconSpan.vue";
import ContextMenuBlank from "@/shared/UI-kits/menus/ui/ContextMenuBlank";
import SearchFieldComponent from "@/shared/UI-kits/forms/SearchFieldComponent";
import RightPopUpComponent from "@/shared/UI-kits/pop-ups/RightPopUpComponent";

export default [
  { name: "LoadingButton", address: LoadingButton },
  { name: "SpinerComponent", address: SpinerComponent },
  { name: "DefaultPopUp", address: DefaultPopUp },
  { name: "ResultPopUp", address: ResultPopUp },
  { name: "DefaultFormLabel", address: DefaultFormLabel },
  { name: "DefaultForm", address: DefaultForm },
  { name: "IconSpan", address: IconSpan },
  { name: "ContextMenu", address: ContextMenuBlank },
  { name: "SearchInput", address: SearchFieldComponent },
  { name: "RightPopUpComponent", address: RightPopUpComponent },
];
