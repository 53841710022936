<!-- компонент-форма для вывода информации по резюме -->
<script>
import {
  returnName,
  getAgeCaseWord,
  getYearsFromMonths,
  getCurrencyIconFromString,
  beautifyNumber,
  getMonthsCaseWord
} from "@/shared/lib/helpers"
import { getGenderByString } from "@/shared/lib/helpers"
import { computed, defineAsyncComponent, toRefs } from "vue"

export default {
  components: {
    WorkExperienceTable: defineAsyncComponent(() => import("@/entities/head-hunter/ui/WorkExperienceTable.vue"))
  },
  props: {
    resume: Object
  },
  setup(props) {
    let { resume } = toRefs(props)
    let candidateName = computed(() => returnName(resume.value)),
      workExperience = computed(() =>
        resume.value.experience != null && resume.value.experience.length > 0
          ? resume.value.experience
          : resume.value.work_history != null && resume.value.work_history.length > 0
          ? resume.value.work_history
          : null
      ),
      gender = computed(() =>
        resume.value.gender != null
          ? resume.value.gender.name != null
            ? getGenderByString(resume.value.gender.name)
            : getGenderByString(resume.value.gender)
          : null
      ),
      age = computed(() =>
        resume.value.age != null ? resume.value.age + " " + getAgeCaseWord(resume.value.age + "") : null
      ),
      birthDate = computed(() =>
        resume.value != null && resume.value.birth_date != null
          ? resume.value.birth_date.split("-").reverse().join(".")
          : null
      ),
      phone = computed(() => {
        if (resume.value != null && resume.value.contact != null && resume.value.contact.length > 0) {
          let result = resume.value.contact.find((el) => el.type != null && el.type.id === "cell")
          return result.value != null && result.value.formatted != null
            ? result.preferred === true
              ? result.value.formatted + " - предпочитаемый способ связи"
              : result.value.formatted
            : null
        }
        if (resume.value != null && resume.value.contact_cell != null) {
          return resume.value.contact_cell
        }
        return null
      }),
      email = computed(() => {
        if (resume.value != null && resume.value.contact != null) {
          let result = resume.value.contact.find((el) => el.type != null && el.type.id === "email")
          return result != null && result.value != null
            ? result.preferred === true
              ? result.value + " - предпочитаемый способ связи"
              : result.value
            : null
        }
        return null
      }),
      relocation = computed(() =>
        resume.value != null && resume.value.relocation != null ? resume.value.relocation.type.name : null
      ),
      businessTripReadiness = computed(() =>
        resume.value != null && resume.value.business_trip_readiness != null
          ? resume.value.business_trip_readiness.name
          : null
      ),
      area = computed(() => (resume.value != null && resume.value.area != null ? resume.value.area.name : null)),
      totalWorkExperience = computed(() => {
        if (
          resume.value != null &&
          resume.value.total_experience != null &&
          resume.value.total_experience.months != null
        ) {
          let result = getYearsFromMonths(resume.value.total_experience.months),
            months = result.months > 0 ? result.months + " " + getMonthsCaseWord(result.months + "") : "",
            years =
              result.years != null && result.years > 0 ? result.years + " " + getAgeCaseWord(result.years + "") : ""
          return years + " " + months
        }
        return null
      }),
      educationLevel = computed(() =>
        resume.value != null && resume.value.education != null && resume.value.education.level
          ? resume.value.education.level.name
          : null
      ),
      educationPrimaryValues = computed(() =>
        resume.value != null && resume.value.education != null && resume.value.education.primary != null
          ? resume.value.education.primary
          : null
      ),
      educationAdditionalValues = computed(() =>
        resume.value != null && resume.value.education != null && resume.value.education.additional != null
          ? resume.value.education.additional
          : null
      )

    const getPhoneFromString = (string) => {
      return string.match(/\d+/g).join("")
    }

    return {
      candidateName,
      workExperience,
      gender,
      age,
      birthDate,
      phone,
      email,
      relocation,
      businessTripReadiness,
      area,
      totalWorkExperience,
      educationLevel,
      educationPrimaryValues,
      educationAdditionalValues,
      returnName,
      getCurrencyIconFromString,
      beautifyNumber,
      getPhoneFromString
    }
  }
}
</script>

<template>
  <div class="resume-blank">
    <div class="resume-blank__container">
      <div class="resume-blank__content" v-if="resume != null">
        <!-- шапка компонента -->
        <div class="resume-blank__top-block flex items-start justify-between">
          <span class="flex items-center">
            <span class="avatar-span w-[100px] h-[100px]">
              <slot name="avatar">
                <img src="@/app/assets/img/icons/default-avatar.svg" alt="" />
              </slot>
            </span>
            <div class="ml-4">
              <a
                :href="resume.alternate_url"
                target="_blank"
                class="flex items-center"
                v-if="resume.platform != null && resume.platform.id == 'headhunter'"
              >
                <h3 class="hover:text-pink">{{ resume.title }}</h3>
                <img src="@/app/assets/img/hh.ru-logo.svg" alt="" class="w-5 h-5 ml-2" />
              </a>
              <p v-if="candidateName != null">{{ candidateName }}</p>
              <p v-else class="text-gray-400">ФИО скрыто соискателем</p>
              <p>{{ gender || "" }} {{ age || "" }} {{ birthDate || "" }}</p>
              <p class="text-lg font-semibold" v-if="resume.salary != null">
                {{ resume.salary != null ? beautifyNumber(resume.salary.amount) : "" }}
                <span class="font-sans ml-0.5 inline-block">
                  {{ getCurrencyIconFromString(resume.salary.currency) }}
                </span>
              </p>
            </div>
          </span>
          <slot name="header-right-block"></slot>
        </div>
        <!---------------------------------------------------------------------->
        <!-- Контакты -->
        <div class="resume-blank__contacts my-3">
          <h3 class="font-bold text-gray-500">Контакты</h3>
          <p v-if="phone != null">{{ phone }}</p>
          <p v-else class="text-gray-400">Телефон был скрыт соискателем</p>
          <a :href="`https://wa.me/${getPhoneFromString(phone)}`" target="_blank" v-if="phone" class="block my-2">
            <font-awesome-icon icon="fa-brands fa-whatsapp" class="text-[20px]" />
          </a>
          <p v-if="email != null">
            {{ email }}
          </p>
          <p v-else class="text-gray-400">Почта была скрыта соискателем</p>
          <p v-if="area != null && relocation != null && businessTripReadiness != null">
            {{ area + ", " }}
            {{ relocation + ", " }}
            {{ businessTripReadiness + "." }}
          </p>
        </div>
        <!---------------------------------------------------------------------->

        <!-- График работы  -->
        <p v-if="resume.employments != null && resume.employments.length > 0" class="mt-2">
          Занятость:
          <span v-for="(schedule, i) in resume.employments" :key="i">
            {{ schedule.name }}
            {{ i < resume.employments.length - 1 ? ", " : "" }}
          </span>
        </p>
        <p v-if="resume.schedules != null && resume.schedules.length > 0">
          График работы:
          <span v-for="(schedule, i) in resume.schedules" :key="i">
            {{ schedule.name }}
            {{ i < resume.schedules.length - 1 ? ", " : "" }}
          </span>
        </p>
        <!---------------------------------------------------------------------->
        <!-- Опыт работы -->
        <div class="resume-blank__work-experiense my-3" v-if="workExperience != null">
          <p class="text-gray-500 font-bold mb-2">Опыт работы {{ totalWorkExperience }}</p>
          <work-experience-table :work-experience="workExperience" />
        </div>
        <!---------------------------------------------------------------------->

        <!-- Образование -->
        <div class="resume-blank__education my-3" v-if="educationPrimaryValues != null">
          <h3 class="text-gray-500">{{ educationLevel }} образование</h3>
          <p v-for="(education, i) in educationPrimaryValues" :key="i" class="font-bold">
            {{ education.name }} ({{ education.year }})
          </p>
        </div>
        <!---------------------------------------------------------------------->

        <!-- Повышение квалификации, курсы -->
        <div
          class="resume-blank__additional-education"
          v-if="educationAdditionalValues != null && educationAdditionalValues.length > 0"
        >
          <h3 class="text-gray-500">Повышение квалификации, курсы</h3>
          <p v-for="(education, i) in educationAdditionalValues" :key="i" class="font-bold">
            {{ education.name }} ({{ education.year }})
          </p>
        </div>

        <!---------------------------------------------------------------------->

        <!-- Ключевые навыки -->
        <div class="resume-blank__key-skills mt-3 mb-0">
          <h3 class="text-gray-500 mb-2">Кючевые навыки</h3>
          <span
            v-for="(skill, i) in resume.skill_set"
            :key="i"
            class="inline-block bg-white rounded p-3 mr-2 mb-2 font-semibold"
          >
            {{ skill }}
          </span>
        </div>
        <!---------------------------------------------------------------------->

        <!-- Водительские права -->
        <div
          class="resume-blank__driver-license my-3"
          v-if="resume.driver_license_types != null && resume.driver_license_types.length > 0"
        >
          <h3 class="text-gray-500">Опыт вождения</h3>
          <p v-for="(type, i) in resume.driver_license_types" :key="i">
            Права категории <span class="font-bold">{{ type.id }}</span>
          </p>
        </div>
        <!---------------------------------------------------------------------->
        <!-- Языки -->
        <div class="resume-blank__languages my-3" v-if="resume.language != null && resume.language.length > 0">
          <h3 class="text-gray-500">Языки</h3>
          <p v-for="(lang, i) in resume.language" :key="i">
            {{ lang.name }} &mdash;
            {{ lang.level.name }}
          </p>
        </div>
        <!---------------------------------------------------------------------->
        <!--  -->
        <div class="resume-blank__about my-3" v-if="resume.skills != null">
          <h3 class="text-gray-500">Обо мне</h3>

          <p>{{ resume.skills }}</p>
        </div>
        <!---------------------------------------------------------------------->
        <!--  -->
        <!-- <div class="resume-blank__about">
          <h3 class="text-gray-500">Обо мне</h3>
        </div> -->
        <!---------------------------------------------------------------------->
        <!-- <pre>{{ resume }}</pre> -->
      </div>
      <div class="resume-blank__no-content" v-else></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.resume-blank {
  &__about {
    p {
      white-space: pre-wrap;
    }
  }
}
</style>
@/shared/lib/helpers/get-months-case-word @/shared/lib/helpers/get-gender-by-string
