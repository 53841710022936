import hrPagesPaths from "./hr-pages-paths";

export default [
  {
    path: "/hr-main-page",
    alias: "/",
    component: () =>
      import(/* webpackChunkName: "user-hr" */ "@/views/users/hrs/HrView.vue"),
    children: [...hrPagesPaths],
  },
];
