import { API } from "@/shared/API"
import VacancyStatisticAPI from "./statistics"

export { VacancyStatisticAPI }
export * from "./head-hunter"

export class VacancyAPI {
  /**
   * Запрос на все вакансии с пагинацией
   * @param {boolean} archived статус архивности вакансий
   * @param {?number} page страница для добавления
   * @param {?number} perPage кол-во вакансий на странице
   * @returns {Promise}
   */
  static fetchAllMyVacancys(archived, page = 0, perPage = 25) {
    let request = new API({
      method: "vacancy.get_vacancies",
      params: {
        recruiter: "me",
        archived: archived,
        page: page,
        per_page: perPage
      }
    })
    return request.sendRequest()
  }

  // метод для поиска вакансий
  static async fetchSearchVacancies(searchPhrase) {
    let request = new API({
      method: "core.search_everything",
      params: {
        search_phrase: searchPhrase /* @type string */,
        search_area: "vacancies" /* @values resumes/vacancies */
      }
    })
    return await request.sendRequest()
  }

  /**
   * Запрос на детальную информацию по вакансии
   * @param {number | string} id в нашей системе
   * @returns {Promise}
   */
  static fetchVacancyDetails(id) {
    let request = new API({
      method: "vacancy.vacancy_details",
      params: {
        vacancy_id: +id
      }
    })
    return request.sendRequest()
  }
  /**
   * Запрос для обновления детальной информации по вакансии
   * @param {{}} details
   * @returns {Promise}
   */
  static fetchUpdateVacancyDetails(details) {
    console.log(details)
    let request = new API({
      method: "vacancy.update",
      params: {
        id: +details.id,
        recruiter: details.recruterId,
        dop_recruiter: details.dopRecruters,
        // observers: details.observers,
        // managers: details.managers,
        // technical_experts: details.statusStaff,
        status_staff: details.statusStaff,
        billing_type: details.billingType,
        schedule: details.schedule,
        employment: details.employment,
        salary_currency: details.salaryCurrency,
        status: details.status,
        address_work: details.addressWorkId,
        employer: details.employer,
        professional_roles: details.professionalRoles,
        name: details.name,
        hh_id: details.hhId,
        start_count: details.startCount,
        driver_license_types: details.driverLicenseTypes,
        work_experience_in_years: details.workExperienceInYears,
        salary_min: details.salaryMin,
        salary_max: details.salaryMax,
        outside_customer: details.outsideCustomer,
        outside_customer_name: details.outsideCustomerName,
        link_career_page: details.linkCareerPage,
        link_test: details.linkTest,
        comment: details.comment,
        key_skills: details.keySkills,
        publish_website: details.publishWebsite,
        detail_address: details.detailAddress,
        template_blank: false,
        hh_description: details.hhDescription
      }
    })
    return request.sendRequest()
  }
  /**
   * Запрос для создания вакансии
   * @param {{}} details
   * @returns {Promise}
   */
  static fetchCreateVacancyDetails(details) {
    let request = new API({
      method: "vacancy.create",
      params: {
        recruiter: details.recruterId,
        dop_recruiter: details.dopRecruters,
        status_staff: details.statusStaff,
        billing_type: details.billingType,
        schedule: details.schedule,
        employment: details.employment,
        salary_currency: details.salaryCurrency,
        status: details.status,
        address_work: details.addressWorkId,
        employer: details.employer,
        professional_roles: details.professionalRoles,
        name: details.name,
        hh_id: details.hhId,
        start_count: details.startCount,
        driver_license_types: details.driverLicenseTypes,
        work_experience_in_years: details.workExperienceInYears,
        salary_min: details.salaryMin,
        salary_max: details.salaryMax,
        outside_customer: details.outsideCustomer,
        outside_customer_name: details.outsideCustomerName,
        link_career_page: details.linkCareerPage,
        link_test: details.linkTest,
        comment: details.comment,
        key_skills: details.keySkills,
        publish_website: details.publishWebsite,
        detail_address: details.detailAddress,
        template_blank: false
      }
    })
    return request.sendRequest()
  }
  /**
   * метод для получения ступеней канбана
   * @returns {Promise}
   */
  static fetchVacancyKanbanSteps() {
    let request = new API({
      method: "vacancy.get_steps"
    })
    return request.sendRequest()
  }
  /**
   * метод получения ступеней с резюме для канбана
   * @param { string | number } vacancyId
   * @returns { Promise }
   */

  static fetchResumesStepsByVacancyId(vacancyId) {
    let request = new API({
      method: "vacancy.get_steps_resumes_by_vacancy",
      params: { vacancy_id: vacancyId }
    })
    return request.sendRequest()
  }
  /**
   * метод для перемещения из активных вакансий в архив
   * @param {{ vacancyId: string | number }} param
   * @returns { Promise }
   */
  static fetchMoveVacancysToArchive({ vacancyIds }) {
    let request = new API({
      method: "vacancy.move_vacancy_to_archive",
      params: {
        vacancy_ids: vacancyIds
      }
    })
    return request.sendRequest()
  }
  /**
   * метод удаления вакансий
   * @param {{ vacancyId: string | number }} param
   * @returns { Promise }
   */
  static async fetchDeleteVacancys({ vacancyIds }) {
    let request = new API({
      method: "vacancy.delete",
      params: {
        vacancy_ids: vacancyIds
      }
    })
    return await request.sendRequest().then((data) => {
      console.log(data)
      if (data.error == false && data.result.fail && data.result.fail.length > 0) {
        return {
          error: true,
          message: data.result.error_msg
        }
      }
      return data
    })
  }
  /**
   * запрос на перемещение вакансии в удаленные (но чтобы оконьчательно восстановить вакансию нужно vacancy.create)
   * @param { string | number } vacancyId
   * @returns { Promise }
   */
  static fetchRestoreVacancy(vacancyId) {
    let request = new API({
      method: "vacancy.create_new_vacancy_based_on_an_archived_vacancy",
      params: {
        vacancy_id: vacancyId
      }
    })
    return request.sendRequest()
  }
}
