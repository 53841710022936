export function getYearsFromMonths(months) {
  if (!months) return null

  let years = Math.floor(months / 12)
  let resultMonths = months - years * 12

  return {
    years: years == 0 ? null : years,
    months: resultMonths == 0 ? null : resultMonths
  }
}
