export let removeContextMenu = (nodeElem) => {
  if (nodeElem != null) {
    nodeElem.classList.remove("active");
  }
};
export let addContextMenu = (event, nodeElem) => {
  nodeElem != null ? nodeElem.classList.add("active") : null;

  setPositionContextMenu(event, nodeElem);
};
export let toggleContextMenu = (event, nodeElem) => {
  nodeElem != null ? nodeElem.classList.toggle("active") : null;
  setPositionContextMenu(event, nodeElem);
};

let setPositionContextMenu = (event, elem) => {
  let elementCoordinates = elem.getBoundingClientRect();
  let contentHeight = window.innerHeight;

  if (
    contentHeight - (contentHeight - elementCoordinates.bottom) >
      elementCoordinates.height &&
    elementCoordinates.bottom < contentHeight &&
    elementCoordinates.bottom + elementCoordinates.height < contentHeight
  ) {
    elem != null ? (elem.style.left = event.clientX + "px") : null;
    elem != null ? (elem.style.top = event.clientY + "px") : null;
  } else {
    elem != null ? (elem.style.left = event.clientX + "px") : null;
    elem != null
      ? (elem.style.top = event.clientY - elementCoordinates.height + 10 + "px")
      : null;
  }
};
