/**
 *
 * @param {File} file - файл для форматирования
 * @returns {string} - возвращает строку
 */
export async function formatFileToBase64(file) {
  return new Promise((res, rej) => {
    let reader = new FileReader();

    file != null && reader.readAsDataURL(file);

    reader.onload = () => {
      return res(reader.result);
    };

    reader.onerror = () => {
      return rej(error);
    };
  });
}
