/**
 * функция для вывода иконки валюты
 * @param {string} string строка, содержащая название валюты
 * @returns { string }
 */
export function getCurrencyIconFromString(string) {
  let stringHasRubles = string.toLowerCase().match(/ru[br]/gi),
    stringHasEuro = string.toLowerCase().match(/eur/gi),
    stringHasUsd = string.toLowerCase().match(/usd/gi)
  if (stringHasRubles != null && stringHasRubles.length > 0) {
    return "\u20bd"
  }
  if (stringHasEuro != null && stringHasEuro.length > 0) {
    return "\u20ac"
  }
  if (stringHasUsd != null && stringHasUsd.length > 0) {
    return "\uff04"
  }
  return string
}
