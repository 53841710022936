import { API } from "@/shared/API"

export class ViewerAPI {
  static async fetchUserInfo() {
    let request = new API({
      method: "core.get_user_info"
    })

    return await request.sendRequest()
  }
}
