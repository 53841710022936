import axios from "axios"

// базовый конфиг axios для формирования POST запросов
let config = {
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
}

export const noTokenInstance = axios.create(config)

noTokenInstance.interceptors.response.use(
  async (responce) => {
    console.log(responce)

    if (responce != null && responce.data && responce.data.error) {
      let error = responce.data.error
      Promise.reject(error.message)

      return {
        error: true,
        message: error.message,
        code: error.code
      }
    }

    return {
      error: false,
      result: responce.data.result
    }
  },
  (error) => {
    console.log(error)
    if (error != null && error.message && error.code) {
      Promise.reject(error.message)
      return {
        error: true,
        message: error.message,
        code: error.code
      }
    }

    if (error != null && error.name && error.name == "AxiosError") {
      Promise.reject(error.message)

      return {
        error: true,
        message: error.message
      }
    }

    Promise.reject(error)
    return {
      error: true,
      data: error
    }
  }
)
