export function getMonthsCaseWord(months) {
  let monthToString = "" + months;
  if (monthToString != null && monthToString != "") {
    return monthToString.match(/1[2-9]|^1$|[2-9]+1$/g)
      ? "месяц"
      : monthToString.match(/[2-4]+/g)
      ? "месяца"
      : monthToString.match(/[5-9]/g) || monthToString.match(/1[01]+/g)
      ? "месяцев"
      : "";
  }
  return "";
}
