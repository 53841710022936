import { API } from "@/shared/API"

export default class HHApi {
  constructor() {}
  // метод для различных гет запросов (нужен только url)
  static async fetchRequest(url) {
    let request = new API({
      method: "vacancy.view_negotiation",
      params: { url }
    })
    return await request.sendRequest()
  }

  // запрос на получение ссылки на регистрацию ХХ
  static async fetchHHApplicationAcceptUrl() {
    let request = new API({
      method: "hh_ru.get_application_accept_url"
    })
    return await request.sendRequest()
  }

  // запрос на обновление токена
  static async fetchUpdateHHTokenByCode(code) {
    let request = new API({
      method: "hh_ru.bind_account_to_hh",
      params: { code }
    })
    return await request.sendRequest()
  }
}
