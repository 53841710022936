<script>
export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: [Object, Array],
      // default: () => ({ required: false, email: false }),
    },
  },
  data() {
    return {};
  },
};
</script>
<template>
  <span class="form__label-block">
    <label class="block">
      <slot name="label-text"></slot>
    </label>
    <slot
      name="input"
      :wrong="
        validator != null &&
        (validator.required || validator.email || validator.sameAsPassword)
      "
    ></slot>
    <span
      class="wrong-text"
      v-if="validator != null && validator.required == true"
    >
      Это поле обязательно
    </span>
    <span
      class="wrong-text"
      v-else-if="validator != null && validator.email == true"
    >
      Почта заполнена неправильно
    </span>
    <span
      class="wrong-text"
      v-else-if="validator != null && validator.sameAsPassword == true"
    >
      Пароль не совпадает с предыдущим
    </span>
  </span>
</template>

<style lang="scss" scoped></style>
