<template>
  <span
    class="icon-span"
    :style="`
      width: ${width}px;
      height: ${height}px;`"
  >
  </span>
</template>

<script>
export default {
  props: {
    width: [Number, String],
    height: [Number, String],
  },
};
</script>

<style lang="scss" scoped>
.icon-span {
  display: block;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
}
</style>
