export function getGenderByString(data) {
  let regMale = data.match(/Муж/gi),
    regFemale = data.match(/Жен/gi);

  if (regMale != null && regMale.length > 0) {
    return "Мужчина";
  }
  if (regFemale != null && regFemale.length > 0) {
    return "Женщина";
  }
}
