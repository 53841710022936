<script>
import { convertVacancyFormData, getAddressValue, setValuesInVacancyForm } from "../../index"
import useVuelidate from "@vuelidate/core"
import { required, requiredIf } from "@vuelidate/validators"
import { reactive, ref, computed, watch, onMounted, inject } from "vue"
import { useStore } from "vuex"
import { returnName } from "@/shared/lib/helpers"

export default {
  props: {
    formValues: { type: Object, default: null }
  },
  async setup(props, { expose }) {
    let store = useStore()
    let fieldsOptions = computed(() => store.getters.getFields)
    let multipleAccordion = ref(false)
    let activeIndex = ref(0)
    let form = reactive({
        mainTitle: null,
        emploer: null,
        startNumberPositions: null,
        jobPlace: null,
        workExperienceYears: null,
        workSchedule: null,
        professionalRoles: null,
        driverLicense: [],
        statusStaff: null,
        comment: null,
        keySkils: null,
        billingType: null,
        salaryCurrency: null,
        salaryMax: null,
        salaryMin: null,
        hasOutsideCustomer: false,
        outsideCustomerName: null,
        linkCareerPage: null,
        linkTest: null,
        recruiter: null,
        dopRecruiters: null,
        managers: null,
        status: null,
        employment: null,
        publishWebsite: false,
        hhDescription: null,
        detailAddress: null
      }),
      userInfo = inject("user", null)
    let resetFormValues = () => {
      let boolValues = ["hasOutsideCustomer", "publishWebsite"]
      for (let key in form) {
        if (!boolValues.includes(key)) {
          form[key] = null
        } else {
          form[key] = false
        }
      }
      return v$.value.$reset()
    }
    let setFormValues = () => {
      if (props.formValues != null) {
        setValuesInVacancyForm({
          form: form,
          values: props.formValues,
          options: fieldsOptions.value
        })
      }
    }
    let getFormValues = () => {
      return convertVacancyFormData(form)
    }
    let activateAccordionTabs = () => {
      multipleAccordion.value = true
      activeIndex.value = [0, 1, 2, 3]
    }
    let validateForm = async () => {
      if (v$.value.$invalid) {
        let validate = new Promise((res) => {
          res(v$.value.$touch())
        })
        await validate
        activateAccordionTabs()
        let erroredElement = document.querySelector(".wrong")
        erroredElement != null && erroredElement.scrollIntoView({ block: "center" })
        return false
      }
      return true
    }

    onMounted(() => {
      setFormValues()
    })
    watch(fieldsOptions.value, () => setFormValues())

    let validateRules = computed(() => ({
      mainTitle: { required },
      billingType: { required },
      detailAddress: { required },
      professionalRoles: { required },
      hhDescription: { requiredIf: requiredIf(form.publishWebsite) },
      recruiter: { required },
      status: { required }
    }))
    let v$ = useVuelidate(validateRules, form)

    expose({
      getFormValues,
      validateForm,
      resetFormValues
    })

    return {
      form,
      multipleAccordion,
      v$,
      fieldsOptions,
      activeIndex,
      userInfo,
      returnName,
      getFormValues,
      validateForm,
      resetFormValues,
      getAddressValue
    }
  }
}
</script>

<template>
  <default-form>
    <!-- <pre>{{ $ref }}</pre> -->
    <accordion-component :multiple="multipleAccordion" :activeIndex="activeIndex">
      <accordion-tab ref="accordionTab" header="Информация о вакансии">
        <default-form-label
          :validator="{
            required: v$.mainTitle.$invalid && v$.mainTitle.$dirty
          }"
          required
        >
          <template #label-text>Название вакансии</template>
          <template v-slot:input="value">
            <input-text :class="{ wrong: value != null ? value.wrong : false }" v-model.trim="form.mainTitle" />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Работодатель</template>
          <template v-slot:input>
            <dropdown-component
              placeholder="Выберите значение из списка"
              :options="fieldsOptions.employers"
              option-label="name"
              v-model.trim="form.emploer"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Стартовое число позиций</template>
          <template #input>
            <input-number mode="decimal" v-model="form.startNumberPositions" placeholder="Введите целое число" />
          </template>
        </default-form-label>
        <default-form-label
          :validator="{
            required: v$.professionalRoles.$invalid && v$.professionalRoles.$dirty
          }"
          required
        >
          <template #label-text>Профессиональная роль</template>
          <template v-slot:input="value">
            <multi-select
              :class="{ wrong: value != null ? value.wrong : false }"
              v-model.trim="form.professionalRoles"
              placeholder="Выберите значения из списка"
              option-label="name"
              :filter="true"
              filter-placeholder="Поиск"
              scrollable
              :options="fieldsOptions.professionalRoles"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Скилы</template>
          <template v-slot:input>
            <multi-select
              v-model="form.keySkils"
              placeholder="Выберите значения из списка"
              :options="fieldsOptions.skills"
              scrollable
              :filter="true"
              filter-placeholder="Поиск"
              option-label="name"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Категории водительских прав</template>
          <template v-slot:input>
            <multi-select
              v-model="form.driverLicense"
              :options="fieldsOptions.driverLicenseTypes"
              option-label="name"
              placeholder="Выберите значения из списка"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Статус персонала</template>
          <template v-slot:input>
            <dropdown-component
              option-label="name"
              :options="fieldsOptions.statusStaff"
              v-model="form.statusStaff"
              placeholder="Выберите значение из списка"
            />
          </template>
        </default-form-label>
        <default-form-label :validator="{ required: v$.status.$invalid && v$.status.$dirty }" required>
          <template #label-text>Статус вакансии</template>
          <template v-slot:input="value">
            <dropdown-component
              :class="{ wrong: value != null ? value.wrong : false }"
              option-label="name"
              :options="fieldsOptions.statuses"
              v-model="form.status"
              placeholder="Выберите значение из списка"
            />
          </template>
        </default-form-label>
        <default-form-label
          :validator="{
            required: v$.billingType.$invalid && v$.billingType.$dirty
          }"
          required
        >
          <template #label-text>Биллинговый тип</template>
          <template v-slot:input="value">
            <dropdown-component
              :class="{ wrong: value != null ? value.wrong : false }"
              v-model="form.billingType"
              :options="fieldsOptions.billingTypes"
              option-label="name"
              placeholder="Выберите значение из списка"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Опыт работы (лет)</template>
          <template v-slot:input>
            <input-number mode="decimal" v-model="form.workExperienceYears" placeholder="Введите целое число" />
          </template>
        </default-form-label>
        <default-form-label
          :validator="{
            required: v$.recruiter.$invalid && v$.recruiter.$dirty
          }"
          required
        >
          <template #label-text>Рекрутер</template>
          <template v-slot:input="value">
            <dropdown-component
              :class="{ wrong: value.wrong }"
              :options="fieldsOptions.workers"
              :option-label="(val) => returnName(val)"
              v-model.trim="form.recruiter"
              placeholder="Выберите значения из списка"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Дополнительные рекрутеры</template>
          <template v-slot:input>
            <multi-select
              :options="fieldsOptions.workers"
              :option-label="(val) => returnName(val)"
              v-model.trim="form.dopRecruiters"
              placeholder="Выберите значения из списка"
            />
          </template>
        </default-form-label>
      </accordion-tab>
      <accordion-tab ref="accordionTab" header="Локация работы">
        <default-form-label
          :validator="{
            required: v$.detailAddress.$invalid && v$.detailAddress.$dirty
          }"
          required
        >
          <template #label-text>Адрес работы (город или область)</template>
          <template v-slot:input="value">
            <dropdown-component
              :class="{ wrong: value.wrong }"
              placeholder="Выберите значение из списка"
              :filter="true"
              filter-placeholder="Поиск"
              scrollable
              :virtualScrollerOptions="{ itemSize: 46 }"
              :option-label="(val) => getAddressValue(val)"
              :options="
                fieldsOptions != null && fieldsOptions.addressWorkDetailed != null
                  ? fieldsOptions.addressWorkDetailed
                  : null
              "
              v-model="form.detailAddress"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>График работы</template>
          <template v-slot:input>
            <dropdown-component
              placeholder="Выберите значение из списка"
              v-model="form.workSchedule"
              option-label="name"
              :options="fieldsOptions != null && fieldsOptions.schedule != null ? fieldsOptions.schedule : null"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Занятость</template>
          <template v-slot:input>
            <dropdown-component
              placeholder="Выберите значение из списка"
              v-model="form.employment"
              option-label="name"
              :options="fieldsOptions != null && fieldsOptions.employment != null ? fieldsOptions.employment : null"
            />
          </template>
        </default-form-label>
      </accordion-tab>
      <accordion-tab ref="accordionTab" header="Оплата работы">
        <default-form-label>
          <template #label-text>Валюта</template>
          <template v-slot:input>
            <dropdown-component
              placeholder="Выберите значение из списка"
              v-model="form.salaryCurrency"
              :options="fieldsOptions.salaryCurrency"
              option-label="name"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Минимальная зарплата</template>
          <template v-slot:input>
            <input-number
              placeholder="Введите целое число"
              v-model="form.salaryMin"
              mode="decimal"
              currency="RUB"
              locale="ru"
            />
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Максимальная зарплата</template>
          <template #input>
            <input-number v-model="form.salaryMax" placeholder="Введите целое число" />
          </template>
        </default-form-label>
      </accordion-tab>
      <accordion-tab ref="accordionTab" header="Внешний заказчик">
        <default-form-label>
          <template #label-text></template>
          <template #input>
            <span class="flex items-center">
              <check-box input-id="hasOutsideCustomer" :binary="true" v-model="form.hasOutsideCustomer" class="mr-2" />
              <label for="hasOutsideCustomer">Внешний заказчик</label>
            </span>
          </template>
        </default-form-label>
        <default-form-label>
          <template #label-text>Название внешнего заказчика</template>
          <template #input>
            <input-text placeholder="Введите текст" v-model="form.outsideCustomerName" />
          </template>
        </default-form-label>
      </accordion-tab>
    </accordion-component>

    <default-form-label>
      <template #label-text>Комментарий</template>
      <template #input>
        <editor-component editorStyle="height: 320px" v-model="form.comment" placeholder="Введите текст">
          <template v-slot:toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
          </template>
        </editor-component>
      </template>
    </default-form-label>
    <default-form-label>
      <template #label-text>Ссылка на карьерную страницу</template>
      <template #input>
        <input-text placeholder="Введите адрес ссылки" v-model="form.linkCareerPage" />
      </template>
    </default-form-label>
    <default-form-label>
      <template #label-text>Ссылка на тест</template>
      <template #input>
        <input-text v-model="form.linkTest" placeholder="Введите адрес ссылки" />
      </template>
    </default-form-label>
    <slot name="public-on-hh">
      <default-form-label v-if="userInfo != null && userInfo.is_hh_user === true">
        <template #input>
          <span class="flex items-center">
            <check-box inputId="forHH" :binary="true" v-model="form.publishWebsite" class="mr-2" />
            <label for="forHH" class="font-bold">Опубликовать на HH</label>
          </span>
        </template>
      </default-form-label>
      <default-form-label
        v-if="form.publishWebsite == true"
        :validator="{
          required: v$.hhDescription.requiredIf.$invalid && v$.hhDescription.$dirty
        }"
        required
      >
        <template #label-text>Комментарий для публикации вакансии</template>
        <template #input>
          <editor-component editorStyle="height: 320px" v-model="form.hhDescription">
            <template v-slot:toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </editor-component>
        </template>
      </default-form-label>
    </slot>

    <div class="flex items-center">
      <slot name="buttons"></slot>
    </div>
  </default-form>
</template>

<style lang="scss" scoped></style>
