<script>
import { ref } from "vue";

export default {
  setup(_, { expose }) {
    let refFormElem = ref(null);
    let resetFormFields = (form) => {
      for (let key in form) {
        form[key] = null;
      }
    };

    expose({ resetFormFields, nodeElem: refFormElem });
    return { refFormElem };
  },
};
</script>
<template>
  <form class="form" ref="refFormElem" @submit.prevent>
    <h1 class="form__title"><slot name="form-title"></slot></h1>
    <div class="form__container">
      <slot></slot>
    </div>
  </form>
</template>

<style lang="scss" scoped></style>
