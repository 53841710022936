import HHResponsesAPI from "@/shared/API/additional-functional/head-hunter/class-HH-responses";

export default {
  async requestGetAllResponsesOfVacancy(
    { commit, getters },
    { vacancyId, archived }
  ) {
    let vacancy = getters.getVacancyById(vacancyId, archived);
    return await HHResponsesAPI.fetchGetAllRespnsesOfVacancy(vacancyId).then(
      (data) => {
        console.log(data);
        commit("ADD_ALL_RESPONSES", { data: data.result, vacancy });

        return data;
      }
    );
  },
  // запрос на получение коллексции статусов откликов по вакансии
  async requestGetCollectionOfVacancy(
    { commit, getters },
    { vacancyId, archived }
  ) {
    let vacancy = getters.getVacancyById(vacancyId, archived);
    if (vacancy != null) {
      return await HHResponsesAPI.fetchGetCollectionsByVacancy(vacancyId).then(
        (data) => {
          if (data && data.error === false) {
            console.log(data);
            commit("ADD_RESPONSES_COLLECTIONS", {
              responsesCollections: data.result.states,
              vacancy,
            });
          }
          return data;
        }
      );
    }
  },
  // запрос на получение откликов по каждой коллекции в вакансии
  async requestGetResponsesByVacancyCollections(
    { getters, commit },
    { vacancyId, archived }
  ) {
    let vacancy = getters.getVacancyById(vacancyId, archived);

    if (vacancy != null && vacancy.reponses_collections != null) {
      for (let collection of vacancy.reponses_collections) {
        await HHResponsesAPI.fetchGetNegotiationsByCollection(
          collection.url
        ).then((data) => {
          if (data && data.error === false) {
            commit("ADD_RESPONSES_TO_COLLECTION", {
              collection,
              responsesData: data.result.items,
            });
            commit("SET_SAVED_RESPONSES", {
              vacancy,
              resumes: getters.getResumes,
            });
          }
          return data;
        });
      }
    }
  },
};
